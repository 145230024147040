export type CustomQuoteParams = {
  note: string;
  proof?: File;
  me: {
    chargeAmount: number;
  };
  spouse?: {
    chargeAmount: number;
  };
  sons: {
    chargeAmount: number;
  }[];
  daughters: {
    chargeAmount: number;
  }[];
};

export const serializeCustomFormData = ({ note, proof, me, spouse, sons, daughters }: CustomQuoteParams): FormData => {
  const formData = new FormData();

  if (proof) {
    formData.append('proof', proof);
  }

  if (note) {
    formData.append('note', note);
  }

  if (me) {
    formData.append('me[charge_amount]', me.chargeAmount.toString());
  }

  if (spouse) {
    formData.append('spouse[charge_amount]', spouse.chargeAmount.toString());
  }

  if (sons) {
    sons.forEach((son) => {
      formData.append('sons[][charge_amount]', son.chargeAmount.toString());
    });
  }

  if (daughters) {
    daughters.forEach((daughter) => {
      formData.append('daughters[][charge_amount]', daughter.chargeAmount.toString());
    });
  }

  return formData;
};
