// 2024-11-26 14:47:39 +0700

export default {
  required: 'មិនអាចរំលង',
  validPhone: 'ត្រូវតែជាលេខទូរស័ព្ទត្រឹមត្រូវសម្រាប់តំបន់ {{countryName}}',
  min: 'ត្រូវតែមានយ៉ាងហោចណាស់ ${min} តួអក្សរ',
  length: 'ត្រូវតែពិតប្រាកដ ${length} តួអក្សរ',
  notANumber: 'មិនមែនជាលេខទេ',
  invalidFormat: 'ទំរង់មិនត្រឹមត្រូវ',
  limitOutOfRange: 'លើសចំនួនកំណត់នៃអតិបរិមា {{max}}',
  fileSizeOutOfRange: 'ទំហំឯកសារលើសចំនួនកំណត់',
  inclusion: 'មិនត្រូវគ្នា',
  invalidDobWithAge: 'មិនត្រូវគ្នាជាមួយនឹងអាយុ',
  positive: 'ត្រូវតែជាលេខវិជ្ជមាន',
  range: 'ត្រូវតែនៅចន្លោះ {{min}} និង {{max}}',
  minDate: 'ត្រូវតែចាប់ពីកាលបរិច្ឆេទ {{minDate}}',
};
