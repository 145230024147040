import { call, put, takeLatest } from 'redux-saga/effects';

import { PersonalAccidentQuoteApi } from '@apis/PersonalAccidentQuoteApi';
import { PersonalAccidentOrderApi } from '@apis/PersonalAccidentOrderApi';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { PersonalAccidentOrder } from '@models/PersonalAccidentOrder';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';

import {
  personalAccidentQuoteFetch,
  personalAccidentQuoteCreate,
  personalAccidentQuoteEdit,
  personalAccidentQuoteUpdate,
  personalAccidentOrderCreate,
  stateUpdate,
  StateUpdatePayload,
  personalAccidentQuoteCustom,
} from './action';
import { PersonalAccidentQuoteFetchPayload } from './personalAccidentQuoteFetch';
import { PersonalAccidentQuoteCreatePayload } from './personalAccidentQuoteCreate';
import { PersonalAccidentQuoteEditPayload } from './personalAccidentQuoteEdit';
import { PersonalAccidentQuoteUpdatePayload } from './personalAccidentQuoteUpdate';
import { PersonalAccidentOrderCreatePayload } from './personalAccidentOrderCreate';
import { getPersonalAccidentQuoteBuyReviewPageRoute } from './route';
import { getPersonalAccidentOrderPaymentPageRoute } from '@components/PersonalAccidentOrderPaymentPage/route';
import { PersonalAccidentQuoteCustomPayload } from './personalAccidentQuoteCustom';

function* handlePersonalAccidentQuoteFetch({ payload }: SagaPayload<PersonalAccidentQuoteFetchPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.get, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentQuoteCreate({ payload }: SagaPayload<PersonalAccidentQuoteCreatePayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.create, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
    history.navigate(
      getPersonalAccidentQuoteBuyReviewPageRoute({
        personalAccidentQuoteId: state.personalAccidentQuote.personalAccidentOrderId,
      }),
    );
  } catch {}
}

function* handlePersonalAccidentQuoteEdit({ payload }: SagaPayload<PersonalAccidentQuoteEditPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.edit, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentQuoteUpdate({ payload }: SagaPayload<PersonalAccidentQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.update, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
    history.navigate(
      getPersonalAccidentQuoteBuyReviewPageRoute({
        personalAccidentQuoteId: state.personalAccidentQuote.personalAccidentOrderId,
      }),
    );
  } catch {}
}

function* handlePersonalAccidentOrderCreate({ payload }: SagaPayload<PersonalAccidentOrderCreatePayload>) {
  const state: StateUpdatePayload = { personalAccidentOrder: null };
  try {
    state.personalAccidentOrder = (yield call(PersonalAccidentOrderApi.create, payload)) as PersonalAccidentOrder;
    yield put(stateUpdate(state));
    history.navigate(
      getPersonalAccidentOrderPaymentPageRoute({
        personalAccidentOrderId: state.personalAccidentOrder.id,
        billingId: state.personalAccidentOrder.billingId,
      }),
    );
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handlePersonalAccidentQuoteCustom({ payload }: SagaPayload<PersonalAccidentQuoteCustomPayload>) {
  const state: StateUpdatePayload = { personalAccidentQuote: null };
  try {
    state.personalAccidentQuote = (yield call(PersonalAccidentQuoteApi.custom, payload)) as PersonalAccidentQuote;
    yield put(stateUpdate(state));
    history.navigate(
      getPersonalAccidentQuoteBuyReviewPageRoute({
        personalAccidentQuoteId: state.personalAccidentQuote.personalAccidentOrderId,
      }),
    );
  } catch {}
}

export default function* () {
  yield takeLatest(personalAccidentQuoteFetch, handlePersonalAccidentQuoteFetch);
  yield takeLatest(personalAccidentQuoteCreate, handlePersonalAccidentQuoteCreate);
  yield takeLatest(personalAccidentQuoteEdit, handlePersonalAccidentQuoteEdit);
  yield takeLatest(personalAccidentQuoteUpdate, handlePersonalAccidentQuoteUpdate);
  yield takeLatest(personalAccidentOrderCreate, handlePersonalAccidentOrderCreate);
  yield takeLatest(personalAccidentQuoteCustom, handlePersonalAccidentQuoteCustom);
}
