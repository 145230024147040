import React from 'react';
import { Row, Col, Card, Accordion, Stack, Image } from 'react-bootstrap';
import { map, slice } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PersonalAccidentQuoteGroup } from '@models/PersonalAccidentQuoteGroup';

import { PersonalAccidentQuoteItem } from './personal-accident-quote-item';

const PersonalAccidentQuoteGroupItem = ({ insurer, personalAccidentQuotes }: PersonalAccidentQuoteGroup) => {
  const { t } = useTranslation();
  const mainQuote = personalAccidentQuotes[0];
  const moreQuotes = slice(personalAccidentQuotes, 1);
  const shouldRenderMoreQuotes = moreQuotes.length > 0;

  return (
    <Card className="shadow">
      <Card.Body>
        <Row className="g-3">
          <Col lg={2}>
            <Stack direction="horizontal" gap={2} className="justify-content-center">
              <Image src={insurer.logo} className="rounded img-fluid" />
            </Stack>
          </Col>
          <Col lg={10}>
            <PersonalAccidentQuoteItem personalAccidentQuote={mainQuote} />
          </Col>
        </Row>
      </Card.Body>

      {shouldRenderMoreQuotes && (
        <Card.Body>
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Row>
                <Col xs={6} md={2} className="mx-auto">
                  <Accordion.Header className="w-100">
                    {t('healthQuote.viewMore', { count: moreQuotes.length })}
                  </Accordion.Header>
                </Col>
              </Row>
              <Accordion.Body className="mt-3 px-0">
                {map(moreQuotes, (quote, index) => {
                  const shouldRenderBorderBottom = moreQuotes.length > 1 && index !== moreQuotes.length - 1;

                  return (
                    <Row key={quote.personalAccidentPlanId} className="mb-4">
                      <Col
                        lg={{ span: 10, offset: 2 }}
                        className={classNames('pb-4', { 'border-bottom': shouldRenderBorderBottom })}
                      >
                        <PersonalAccidentQuoteItem personalAccidentQuote={quote} />
                      </Col>
                    </Row>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      )}
    </Card>
  );
};

export { PersonalAccidentQuoteGroupItem };
