import React from 'react';

import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import SupportSection from '@components/SupportSection';

import CallToActionSection from './CallToActionSection';
import DefinitionSection from './DefinitionSection';
import { HowItWorksSection } from './how-it-works-section';
import { BenefitSection } from './benefit-section';
import { WhyNeedSection } from './why-need-section';
import { NoCoverSection } from './no-cover-section';
import { WhenBuySection } from './when-buy-section';
import { ChooseRightPlanSection } from './choose-right-plan-section';
import { FrequentlyAskedQuestionSection } from './frequently-asked-question-section';

import { DisclaimerSection } from './disclaimer-section';

export const CancerInsurancePage = () => {
  return (
    <>
      <MetaTag pageName="cancerInsurancePage" />
      <HeaderPrimary />
      <CallToActionSection />
      <DefinitionSection />
      <HowItWorksSection />
      <BenefitSection />
      <WhyNeedSection />
      <NoCoverSection />
      <WhenBuySection />
      <ChooseRightPlanSection />
      <FrequentlyAskedQuestionSection />
      <SupportSection />
      <DisclaimerSection />
      <Footer />
    </>
  );
};
