import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '@components/Base';
import { SupportCard } from '@components/Feature/Support/support-card';
import PersonalAccidentQuotePremiumCardBody from '@components/Feature/PersonalAccidentQuote/PersonalAccidentQuotePremiumCardBody';

import { personalAccidentOrderCreate } from '../action';
import { PersonalAccidentOrderCreatePayload } from '../personalAccidentOrderCreate';

import { PolicySummaryCardBody } from './policy-summary-card-body';
import { FeatureCardBody } from './feature-card-body';
import { BenefitCardBody } from './benefit-card-body';
import { MemberCardBody } from './member-card-body';
import { usePersonalAccidentQuoteReview } from './hook';
import { selectIsCustomer, selectIsFrontline } from '@components/Auth';

import { OrderStatuses } from '@models/Order';
import { getPersonalAccidentQuoteBuyCustomPageRoute, getPersonalAccidentQuoteBuyEditPageRoute } from '../route';

const PersonalAccidentQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { personalAccidentQuote, values } = usePersonalAccidentQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);
  const isCustomer = useSelector(selectIsCustomer);

  const { status, activationDate } = personalAccidentQuote || {};
  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const isValidActivationDate = moment(activationDate).isAfter(moment(), 'day');
  const shouldEdit = isPending && isValidActivationDate;
  const shouldPay = isCustomer && (shouldEdit || isLocked);
  const shouldCustomPremium = isFrontline && isPending;

  if (!personalAccidentQuote) return null;
  const { personalAccidentOrderId } = personalAccidentQuote;

  const handlePay = () => {
    if (!personalAccidentQuote) return null;

    const payload: PersonalAccidentOrderCreatePayload = {
      personalAccidentQuoteId: personalAccidentQuote.personalAccidentOrderId,
    };
    dispatch(personalAccidentOrderCreate(payload));
  };

  const handleCustom = () => {
    navigate(getPersonalAccidentQuoteBuyCustomPageRoute({ personalAccidentOrderId }));
  };

  const handleEdit = () => {
    if (!personalAccidentQuote) return null;

    navigate({
      ...getPersonalAccidentQuoteBuyEditPageRoute({
        personalAccidentQuoteId: personalAccidentQuote.personalAccidentOrderId,
      }),
      search: stringify(values),
    });
  };

  return (
    <>
      {isUndefined(personalAccidentQuote) && <Loading />}
      {personalAccidentQuote && (
        <Container className="my-5">
          <Row>
            <Col lg={7}>
              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>
                <PolicySummaryCardBody {...personalAccidentQuote} />
                <FeatureCardBody {...personalAccidentQuote} />
                <BenefitCardBody {...personalAccidentQuote} />
                <MemberCardBody {...personalAccidentQuote.people} onEdit={shouldEdit ? handleEdit : undefined} />
              </Card>
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <PersonalAccidentQuotePremiumCardBody {...personalAccidentQuote} />

                <Card.Body>
                  {!personalAccidentQuote.isValidMedicalQuestionnaire && (
                    <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
                  )}

                  {isFrontline && (
                    <div className="text-end">
                      <Link
                        to={`/invoices/${personalAccidentQuote.orderEncryptedId}.pdf`}
                        target="_blank"
                        className="d-inline-block my-3"
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {t('common:downloadInvoice')}
                      </Link>
                    </div>
                  )}

                  {shouldCustomPremium && (
                    <Button onClick={handleCustom} className="mb-3 w-100" variant="secondary">
                      Custom
                    </Button>
                  )}

                  {shouldPay && (
                    <Button
                      disabled={!personalAccidentQuote.isValidMedicalQuestionnaire}
                      onClick={handlePay}
                      className="btn-primary btn-lg w-100"
                      size="lg"
                    >
                      <span className="me-2">{t('common:payNow')}</span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}
                </Card.Body>
              </Card>

              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { PersonalAccidentQuoteReviewPage };
