import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useInsuranceProduct } from '@components/InsuranceProduct';

import style from './style.module.scss';

const CallToActionSection = () => {
  const { t } = useTranslation();
  const { items } = useInsuranceProduct();

  return (
    <section data-testid="cta" className={classNames(style.bg, 'py-5')}>
      <Container>
        <Row className="gy-5 align-items-center">
          <Col lg={{ offset: 2, span: 8 }}>
            <h1 className={classNames('fw-bold text-center mt-3 mb-4 lh-base', style.textPreLine)}>
              {t('banner.header1')}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <Row>
              {map(
                items,
                ({ label, href, image, visible }) =>
                  visible && (
                    <Col key={label}>
                      <Link className="d-block text-center text-decoration-none" to={href}>
                        <Image className="shadow" roundedCircle width={150} src={image} />
                        <p className="my-3 fw-bolder">{label}</p>
                      </Link>
                    </Col>
                  ),
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { CallToActionSection };
