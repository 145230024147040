import { stringify } from 'qs';

import {
  PersonalAccidentQuoteDetailsPageParams,
  personalAccidentQuoteDetailsPageParamsSchema,
} from '@components/PersonalAccidentQuoteDetailsRoute/route';
import { replaceRouteParams } from '@utils/route';

export type PersonalAccidentQuoteBuyNewPageParams = PersonalAccidentQuoteDetailsPageParams;

export type PersonalAccidentQuoteBuyEditPageParams = {
  personalAccidentQuoteId: number;
};

export type PersonalAccidentQuoteBuyReviewPageParams = PersonalAccidentQuoteBuyEditPageParams;

export type PersonalAccidentQuoteBuyCustomPageParams = {
  personalAccidentOrderId: number;
};

export const personalAccidentQuoteBuyNewPageParamsSchema = personalAccidentQuoteDetailsPageParamsSchema;

export const PERSONAL_ACCIDENT_QUOTE_BUY_ROUTE_URL = '/personal-accident-insurance-quote-buy/*';

export const PERSONAL_ACCIDENT_QUOTE_BUY_NEW_PAGE_URL = '/personal-accident-insurance-quote-buy';

export const PERSONAL_ACCIDENT_QUOTE_BUY_EDIT_PAGE_URL =
  '/personal-accident-insurance-quote-buy/:personalAccidentQuoteId/edit';

export const PERSONAL_ACCIDENT_QUOTE_BUY_REVIEW_PAGE_URL =
  '/personal-accident-insurance-quote-buy/:personalAccidentQuoteId/review';

export const PERSONAL_ACCIDENT_QUOTE_BUY_CUSTOM_PAGE_URL =
  '/personal-accident-insurance-quote-buy/:personalAccidentOrderId/custom';

export const getPersonalAccidentQuoteBuyNewPageRoute = (params: PersonalAccidentQuoteDetailsPageParams) => {
  return {
    pathname: PERSONAL_ACCIDENT_QUOTE_BUY_NEW_PAGE_URL,
    search: stringify(params),
  };
};

export const getPersonalAccidentQuoteBuyEditPageRoute = (params: PersonalAccidentQuoteBuyEditPageParams) => {
  return {
    pathname: replaceRouteParams(PERSONAL_ACCIDENT_QUOTE_BUY_EDIT_PAGE_URL, params),
  };
};

export const getPersonalAccidentQuoteBuyReviewPageRoute = (params: PersonalAccidentQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(PERSONAL_ACCIDENT_QUOTE_BUY_REVIEW_PAGE_URL, params),
  };
};

export const getPersonalAccidentQuoteBuyCustomPageRoute = (params: PersonalAccidentQuoteBuyCustomPageParams) => {
  return {
    pathname: replaceRouteParams(PERSONAL_ACCIDENT_QUOTE_BUY_CUSTOM_PAGE_URL, params),
  };
};
