import React from 'react';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { FormGroupControl } from '@components/Base';

type Props = {
  name: string;
  label: string;
  age: number;
  isVisible: boolean;
};

const MemberAnswerItem = ({ name, label, age, isVisible }: Props) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <FormGroupControl
      name={name}
      label={`${label} (${t('user.yearsOld', { age: age, pluralizedYear: pluralize(t('common:year'), age) })}), ${t(
        'common:specifyProblem',
      )}`}
      placeholder={t('common:enterDetail')}
      groupProps={{ className: 'my-3' }}
    />
  );
};

export { MemberAnswerItem };
