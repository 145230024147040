import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { CopyLinkButton, Loading } from '@components/Base';
import HealthQuotePremiumCardBody from '@components/HealthQuote/HealthQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';

import { healthOrderCreate, HealthOrderCreatePayload } from '../action';
import { getHealthQuoteBuyCustomPageRoute, getHealthQuoteBuyEditPageRoute } from '../route';
import { PolicySummaryCardBody } from './policy-summary-card-body';
import { FeatureCardBody } from './feature-card-body';
import { BenefitCardBody } from './benefit-card-body';
import { MemberCardBody } from './member-card-body';
import { selectIsFrontline, selectIsCustomer } from '@components/Auth';
import { OrderStatuses } from '@models/Order';

import { useHealthQuoteReview } from './hook';

const HealthQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { healthQuote, values } = useHealthQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);
  const isCustomer = useSelector(selectIsCustomer);

  const isPending = healthQuote?.status === OrderStatuses.Pending;
  const isLocked = healthQuote?.status === OrderStatuses.Locked;
  const isValidActivationDate = moment(healthQuote?.activationDate).isAfter(moment(), 'day');
  const shouldEdit = isPending && isValidActivationDate;
  const shouldPay = isCustomer && (shouldEdit || isLocked);

  const shouldCustomPremium = isFrontline && isPending;

  if (!healthQuote) return null;
  const { healthOrderId } = healthQuote;

  const handlePay = () => {
    if (!healthQuote) return null;

    const payload: HealthOrderCreatePayload = { healthQuoteId: healthQuote.healthOrderId };
    dispatch(healthOrderCreate(payload));
  };

  const handleCustom = () => {
    navigate(getHealthQuoteBuyCustomPageRoute({ healthOrderId }));
  };

  const handleEdit = () => {
    navigate({
      ...getHealthQuoteBuyEditPageRoute({ healthQuoteId: healthQuote.healthOrderId }),
      search: stringify(values),
    });
  };

  return (
    <>
      {isUndefined(healthQuote) && <Loading />}
      {healthQuote && (
        <Container className="my-5">
          <Row>
            <Col lg={7}>
              <div className="d-flex align-items-center justify-content-sm-end mb-3">
                <CopyLinkButton />
              </div>
              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>
                <PolicySummaryCardBody {...healthQuote} />
                <FeatureCardBody {...healthQuote} />
                <BenefitCardBody {...healthQuote} />
                <MemberCardBody {...healthQuote.people} onEdit={shouldEdit ? handleEdit : undefined} />
              </Card>
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <HealthQuotePremiumCardBody {...healthQuote} />

                <Card.Body>
                  {!healthQuote.isValidMedicalQuestionnaire && (
                    <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
                  )}

                  {isFrontline && (
                    <div className="text-end">
                      <Link
                        to={`/invoices/${healthQuote.orderEncryptedId}.pdf`}
                        target="_blank"
                        className="d-inline-block my-3"
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {t('common:downloadInvoice')}
                      </Link>
                    </div>
                  )}

                  {shouldCustomPremium && (
                    <Button onClick={handleCustom} className="mb-3 w-100" variant="secondary">
                      Custom
                    </Button>
                  )}

                  {shouldPay && (
                    <Button
                      disabled={!healthQuote.isValidMedicalQuestionnaire}
                      onClick={handlePay}
                      className="btn-primary btn-lg w-100"
                      size="lg"
                    >
                      <span className="me-2">{t('common:payNow')}</span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}
                </Card.Body>
              </Card>

              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { HealthQuoteReviewPage };
