import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, number, array, string, mixed } from 'yup';
import { pick, includes } from 'lodash';

import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { selectHealthQuoteBuyRoute } from '../selector';
import { formValuesParamsSchema } from './route';

const FILE_SIZE = 5_000_000; //5MB
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const useInitialValues = () => {
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  return useMemo(() => {
    if (!healthQuote) return null;

    const { me, spouse, sons, daughters } = pick(healthQuote.people, getFieldKeys(formValuesParamsSchema));
    const { note } = healthQuote;
    const chargeAmount = (person: { chargeAmount: number }) => ({ chargeAmount: person.chargeAmount });

    const people = {
      me: me && { chargeAmount: me.chargeAmount },
      spouse: spouse ? chargeAmount(spouse) : null,
      sons: sons ? sons.map(chargeAmount) : [],
      daughters: daughters ? daughters.map(chargeAmount) : [],
    };

    return mergeInitialValues({ note }, people);
  }, [healthQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    proof: mixed()
      .optional()
      .test('fileSize', t('validation:fileSizeOutOfRange'), (file: File) => !file || (file && file.size < FILE_SIZE))
      .test(
        'fileFormat',
        t('validation:invalidFormat'),
        (file: File) => !file || (file && includes(SUPPORTED_FORMATS, file.type)),
      ),
    note: string().required(t('validation:required')),
    me: object({ chargeAmount: number().required(t('validation:required')) }),
    spouse: object({ chargeAmount: number().nullable(true) }),
    sons: array().of(object({ chargeAmount: number().required(t('validation:required')) })),
    daughters: array().of(object({ chargeAmount: number().required(t('validation:required')) })),
  });
};
