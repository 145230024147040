const testimonials = [
  {
    id: 1,
    name: 'អេង ហុីម',
    description:
      'MGA Cover គឺជាថ្នាលបច្ចេកវិទ្យាដ៏ល្អមួយដែលខ្ញុំអាចទទួលបានសម្រង់ការធានារ៉ាប់រងរថយន្តរបស់ខ្ញុំក្នុងរយៈពេលតែប៉ុន្មាននាទីប៉ុណ្ណោះ ហើយខ្ញុំបានទិញបណ្ណសន្យារ៉ាប់រងគម្រោងតតិយជន ឬជនទីបី តាមអ៊ីនធឺណិតយ៉ាងងាយស្រួល។ ប្រសិនបើអ្នកមានសំណួរអំពីបណ្ណសន្យាធានារ៉ាប់រង សូមទាក់ទងផ្នែកផ្តល់សេវាអតិថិជនរបស់ពួកគេ ព្រោះពួកគេនឹងឆ្លើយគ្រប់សំនួរទាំងអស់។',
    rating: 5,
  },
  {
    id: 2,
    name: 'Ms. KIM Nacy',
    description:
      'I recently purchased car insurance through MGA Cover, The process was incredibly convenient and fast. The platform is user-friendly, and I felt completely at ease throughout the entire experience. If you’re looking to buy car insurance online, I highly recommend giving MGA Cover a try. Their efficiency and customer satisfaction are top-notch.',
    rating: 5,
  },
  {
    id: 3,
    name: 'Mr. Meas Chetra',
    description:
      "MGA Cover is fantastic! Their website is user-friendly, buying insurance was covenient and fast, and their fast response time makes me feel confident I'm in good hands. Highly recommend!",
    rating: 5,
  },
  {
    id: 4,
    name: 'Ms. PHAN Thida, Chief Nurse Executive',
    description:
      "The customer service is great! They respond quickly to any questions or issues, and the interface is easy to use. The support team is friendly and always helpful. Overall, it's a really positive experience!",
    rating: 5,
  },
  {
    id: 5,
    name: 'Mr. Keo Sambath, HR Manager',
    description:
      "I’m very impressed with the new platform, MGA Cover. I used to struggle when trying to find and compare insurance, but with MGA Cover, everything is very easy and convenient. A few clicks to get a quote and buy insurance—it’s so simple. I would recommend MGA Cover to anyone looking for insurance.",
    rating: 5,
  },

];

export default testimonials;
