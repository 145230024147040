import { mergeInitialValues } from '@utils/formik';
import { useParseParams } from '@hooks/search-params';

import { formValuesParamsSchema, FormValues } from '../PersonalAccidentQuoteBuyForm';
import { usePersonalAccidentQuoteEdit } from '../hook';

export const usePersonalAccidentQuoteReview = () => {
  const personalAccidentQuote = usePersonalAccidentQuoteEdit();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);
  const values = mergeInitialValues(
    initialValues,
    {
      ...personalAccidentQuote?.people,
      activationDate: personalAccidentQuote?.activationDate,
      personalAccidentPlanId: personalAccidentQuote?.personalAccidentPlanId
    }
  );

  return { personalAccidentQuote, values };
};
