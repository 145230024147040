import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import PersonalAccidentQuotePremiumCardBody from '@components/Feature/PersonalAccidentQuote/PersonalAccidentQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupControl } from '@components/Base';
import { tOrdinalItem } from '@components/i18n';
import { usePersonalAccidentQuoteEdit } from '../hook';
import { personalAccidentQuoteCustom } from '../action';
import { PersonalAccidentQuoteCustomPayload } from '../personalAccidentQuoteCustom';
import { FormValues } from './type';
import { useInitialValues, useValidationSchema } from './hook';
import { getPersonalAccidentQuoteBuyReviewPageRoute } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const PersonalAccidentQuoteBuyCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personalAccidentQuote = usePersonalAccidentQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!personalAccidentQuote) return null;

  const { personalAccidentOrderId } = personalAccidentQuote;

  const handleCancel = () => {
    if (!personalAccidentQuote) return null;
    navigate(getPersonalAccidentQuoteBuyReviewPageRoute({ personalAccidentOrderId }), { replace: true });
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: PersonalAccidentQuoteCustomPayload = {
      ...values,
      id: personalAccidentOrderId,
    };

    dispatch(personalAccidentQuoteCustom(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting, values }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupControl
                        required
                        name="me.chargeAmount"
                        label={t('common:myself')}
                        type="number"
                        groupProps={GROUP_PROPS}
                      />

                      {!!values.spouse && (
                        <FormGroupControl
                          required
                          name="spouse.chargeAmount"
                          label={t('common:spouse')}
                          type="number"
                          groupProps={GROUP_PROPS}
                        />
                      )}

                      {!!values.sons &&
                        map(values.sons, (_, idx: number) => (
                          <FormGroupControl
                            required
                            key={idx}
                            name={`sons.${idx}.chargeAmount`}
                            label={tOrdinalItem(idx + 1, 'common:son')}
                            type="number"
                            groupProps={GROUP_PROPS}
                          />
                        ))}

                      {!!values.daughters &&
                        map(values.daughters, (_, idx: number) => (
                          <FormGroupControl
                            required
                            key={idx}
                            name={`daughters.${idx}.chargeAmount`}
                            label={tOrdinalItem(idx + 1, 'common:daughter')}
                            type="number"
                            groupProps={GROUP_PROPS}
                          />
                        ))}

                      <FormGroupControl name="proof" type="file" label={t('common:proof')} groupProps={GROUP_PROPS} />

                      <FormGroupControl required name="note" label={t('common:note')} groupProps={GROUP_PROPS} />
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 mx-auto my-4">
                      <Stack direction="horizontal" gap={3}>
                        <Button onClick={handleCancel} variant="outline-primary" size="lg">
                          {t('common:cancel')}
                        </Button>

                        <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                          {t('common:update')}
                        </Button>
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <PersonalAccidentQuotePremiumCardBody {...personalAccidentQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { PersonalAccidentQuoteBuyCustomPage };
