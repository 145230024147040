import React from 'react';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { Button, Accordion, Card } from 'react-bootstrap';

import { getAge } from '@utils/datetime';
import { tOrdinalItem } from '@components/i18n';
import { StepFormProps } from '@components/Base';

import { FormValues } from './schema';
import { ContactFormControl } from './contact-form-control';

const ContactStepForm = ({ onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();

  return (
    <Card>
      <Card.Body>
        <Accordion defaultActiveKey="me">
          <ContactFormControl
            name="me"
            label={`${t('common:myself')} (${t('user.yearsOld', {
              age: getAge(values.me.dob),
              pluralizedYear: pluralize(t('common:year'), getAge(values.me.dob)),
            })})`}
          />

          {!!values.spouse && (
            <ContactFormControl
              name="spouse"
              label={`${t('common:spouse')} (${t('user.yearsOld', {
                age: getAge(values.spouse.dob),
                pluralizedYear: pluralize(t('common:year'), getAge(values.spouse.dob)),
              })})`}
            />
          )}

          {!!values.sons &&
            map(values.sons, (son, idx) => (
              <ContactFormControl
                key={`sons.${idx}`}
                name={`sons.${idx}`}
                label={`${tOrdinalItem(idx + 1, 'common:son')} (${t('user.yearsOld', {
                  age: getAge(son.dob),
                  pluralizedYear: pluralize(t('common:year'), getAge(son.dob)),
                })})`}
              />
            ))}

          {!!values.daughters &&
            map(values.daughters, (daughter, idx) => (
              <ContactFormControl
                key={`daughters.${idx}`}
                name={`daughters.${idx}`}
                label={`${tOrdinalItem(idx + 1, 'common:daughter')} (${t('user.yearsOld', {
                  age: getAge(daughter.dob),
                  pluralizedYear: pluralize(t('common:year'), getAge(daughter.dob)),
                })})`}
              />
            ))}
        </Accordion>
      </Card.Body>

      <Card.Footer>
        <div className="d-grid">
          <Button onClick={() => onNext()} size="lg">
            {t('common:next')}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export { ContactStepForm };
