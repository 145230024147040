import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys, map } from 'lodash';

import { useFilterContext, FilterType, useFilterParams } from '../FilterProvider';
import { stateReset } from '../action';

const FilterSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters, updateFilters } = useFilterContext();
  const filterParams = useFilterParams();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    updateFilters({ [name]: checked });

    dispatch(stateReset());
  };

  return (
    <Container className="shadow my-3 rounded">
      <div className="d-flex flex-column flex-lg-row justify-content-between py-3">
        <strong className="d-block me-5">{t('healthQuote.filters')}</strong>
        {map(keys(filterParams) as (keyof FilterType)[], (option) => (
          <Form.Check
            id={option}
            key={option}
            checked={filters[option]}
            onChange={handleChange}
            name={option}
            label={t(`healthQuote.${option}`)}
          />
        ))}
      </div>
    </Container>
  );
};

export { FilterSection };
