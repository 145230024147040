import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map, startCase } from 'lodash';

import { amountToUsd } from '@utils/currency';
import { InsurerLogo } from '@components/Feature/Insurer';
import { useHealthQuoteResultPageParams } from '@components/HealthQuoteResultPage';
import { getHealthQuoteBuyNewPageRoute } from '@components/HealthQuoteBuyRoute/route';

import { selectHealthQuoteComparePage } from './selector';

const TableInsurer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const { healthQuotes } = useSelector(selectHealthQuoteComparePage);

  const handleBuy = (healthPlanId: number) => {
    navigate(getHealthQuoteBuyNewPageRoute({ ...params, healthPlanId }));
  };

  return (
    <tr className="shadow-sm sticky-top table-light">
      <th className="w-25 py-4" />
      {map(healthQuotes, ({ healthPlanId, insurer, totalPremium, healthPlanName }) => (
        <th key={healthPlanId} className="py-4">
          <div className="mb-2" style={{ width: 200, height: 80 }}>
            <InsurerLogo insurer={insurer} rounded fluid className="h-100" />
          </div>

          <span className="d-block mb-2">{healthPlanName}</span>

          <Button onClick={() => handleBuy(healthPlanId)} variant="primary" size="lg" style={{ width: 200 }}>
            <small>{startCase(t('common:buyNow'))}</small>
            <span className="fw-bold d-block">{amountToUsd(totalPremium)}</span>
          </Button>
        </th>
      ))}
    </tr>
  );
};

export { TableInsurer };
