import { call, put, takeLatest } from 'redux-saga/effects';

import { CancerQuote } from '@models/CancerQuote';
import { CancerOrder } from '@models/CancerOrder';
import { CancerQuoteApi } from '@apis/CancerQuoteApi';
import { CancerOrderApi } from '@apis/CancerOrderApi';
import { history } from '@utils/history';
import { SagaPayload } from '@utils/typescript';
import { getCancerOrderPaymentPageRoute } from '@components/CancerOrderPaymentPage/route';

import {
  cancerQuoteFetch,
  cancerQuoteCreate,
  cancerQuoteEdit,
  cancerQuoteUpdate,
  cancerOrderCreate,
  stateUpdate,
  StateUpdatePayload,
  cancerQuoteCustom,
} from './action';
import { CancerQuoteFetchPayload } from './cancerQuoteFetch';
import { CancerQuoteCreatePayload } from './cancerQuoteCreate';
import { CancerQuoteEditPayload } from './cancerQuoteEdit';
import { CancerQuoteUpdatePayload } from './cancerQuoteUpdate';
import { CancerOrderCreatePayload } from './cancerOrderCreate';
import { getCancerQuoteBuyReviewPageRoute } from './route';
import { CancerQuoteCustomPayload } from './cancerQuoteCustom';

function* handleCancerQuoteFetch({ payload }: SagaPayload<CancerQuoteFetchPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.get, payload)) as CancerQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerQuoteCreate({ payload }: SagaPayload<CancerQuoteCreatePayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.create, payload)) as CancerQuote;
    yield put(stateUpdate(state));
    history.navigate(getCancerQuoteBuyReviewPageRoute({ cancerQuoteId: state.cancerQuote.cancerOrderId }));
  } catch {}
}

function* handleCancerQuoteEdit({ payload }: SagaPayload<CancerQuoteEditPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.edit, payload)) as CancerQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerQuoteUpdate({ payload }: SagaPayload<CancerQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.update, payload)) as CancerQuote;
    yield put(stateUpdate(state));
    history.navigate(getCancerQuoteBuyReviewPageRoute({ cancerQuoteId: state.cancerQuote.cancerOrderId }));
  } catch {}
}

function* handleCancerOrderCreate({ payload }: SagaPayload<CancerOrderCreatePayload>) {
  const state: StateUpdatePayload = { cancerOrder: null };
  try {
    state.cancerOrder = (yield call(CancerOrderApi.create, payload)) as CancerOrder;
    yield put(stateUpdate(state));
    history.navigate(
      getCancerOrderPaymentPageRoute({ cancerOrderId: state.cancerOrder.id, billingId: state.cancerOrder.billingId }),
    );
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCancerQuoteCustom({ payload }: SagaPayload<CancerQuoteCustomPayload>) {
  const state: StateUpdatePayload = { cancerQuote: null };
  try {
    state.cancerQuote = (yield call(CancerQuoteApi.custom, payload)) as CancerQuote;
    yield put(stateUpdate(state));
    history.navigate(getCancerQuoteBuyReviewPageRoute({ cancerQuoteId: state.cancerQuote.cancerOrderId }));
  } catch {}
}

export default function* () {
  yield takeLatest(cancerQuoteFetch, handleCancerQuoteFetch);
  yield takeLatest(cancerQuoteCreate, handleCancerQuoteCreate);
  yield takeLatest(cancerQuoteEdit, handleCancerQuoteEdit);
  yield takeLatest(cancerQuoteUpdate, handleCancerQuoteUpdate);
  yield takeLatest(cancerOrderCreate, handleCancerOrderCreate);
  yield takeLatest(cancerQuoteCustom, handleCancerQuoteCustom);
}
