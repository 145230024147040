import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { tOrdinalItem } from '@components/i18n';
import { selectCurrentUser } from '@components/Auth';
import { StepFormProps } from '@components/Base';

import { CardHeader } from './card-header';
import { BirthDateFormControl } from './birthdate-form-control';
import { FormValues } from './type';

const BirthDatesStepForm = ({ onPrev, onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const { values } = useFormikContext<FormValues>();

  return (
    <Card className="shadow">
      <CardHeader onClick={() => onPrev?.()} title={t('healthQuoteForm.birthdates.title')} />
      <Card.Body>
        <BirthDateFormControl member="me" title={t('common:myself')} disabled={!!currentUser?.dob} />

        {values.spouse?.checked && <BirthDateFormControl member="spouse" title={t('common:spouse')} />}

        {map(values.sons, (_, idx) => (
          <BirthDateFormControl
            key={`sons-${idx}`}
            member={`sons.${idx}`}
            title={tOrdinalItem(idx + 1, 'common:son')}
          />
        ))}

        {map(values.daughters, (_, idx) => (
          <BirthDateFormControl
            key={`daughters-${idx}`}
            member={`daughters.${idx}`}
            title={tOrdinalItem(idx + 1, 'common:daughter')}
          />
        ))}
      </Card.Body>
      <Card.Footer>
        <div className="d-grid">
          <Button size="lg" onClick={() => onNext()}>
            {t('common:next')}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export { BirthDatesStepForm };
