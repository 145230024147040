import i18n from '@components/i18n';

export const FACEBOOK_MESSENGER_URL = 'https://www.facebook.com/messages/t/399244116951095';
export const FACEBOOK_PAGE_URL = 'https://www.facebook.com/MGAAsia';
export const TELEGRAM_URL = 'https://t.me/mgacoversupport';
export const TELEGRAM_CHANNEL_URL = 'https://t.me/mgacover';
export const MOMENT_TIMEZONE = 'Asia/Bangkok';

export const MGA_PHONE_NUMBER_LINE_1 = '092 777 125';
export const MGA_PHONE_NUMBER_LINE_2 = '016 777 413';
export const MGA_SUPPORT_EMAIL = 'support@mgacover.com';
export const MGA_INFO_EMAIL = 'info@mgacover.com';
export const MGA_PHONE_NUMBER = '1';

export const AFTER_LOGIN_PATH = '/user-dashboard/policy';

export const CHILD_AGE_MIN = 1;
export const CHILD_AGE_MAX = 18;
export const CHILD_OCCUPATIONS = ['Student', 'Stay home'];
export const ADULT_AGE_MIN = 19;
export const ADULT_AGE_MAX = 60;
export const BMI_MIN = 17;
export const BMI_MAX = 33;

export const EFFECTIVE_DAY_MIN = 1;
export const EFFECTIVE_DAY_MAX = 15;

export const API_V1_BASE = `/api/v1/${i18n.language}`;
