// 2025-01-20 12:32:13 +0700

export default {
  customerService: 'ផ្នែកសេវាអតិថិជន',
  insuranceProducts: 'ផលិតផលធានារ៉ាប់រង',
  carInsurance: 'ធានារ៉ាប់រងរថយន្ត',
  ourStory: 'រឿងរ៉ាវរបស់យើង',
  contactUs: 'ទំនាក់ទំនងយើង',
  aboutUs: 'អំពីយើង',
  privacyPolicy: 'គោលការណ៍ភាពឯកជន',
  termsAndConditions: 'ល័ក្ខខ័ណ្ឌនៃការប្រើប្រាស់',
  cancellationAndRefund: 'ទុកជាមោឃ និងសងប្រាក់វិញ',
  legal: 'ផ្លូវច្បាប់',
  sitemap: 'ផែនទីគេហទំព័រ',
  blog: 'ប្លុក',
  allRightsReserved: 'រក្សាសិទ្ធិគ្រប់យ៉ាង',
  signOut: 'ចាកចេញ',
  dashboard: 'ផ្ទាំងគ្រប់គ្រង',
  billing: 'វិក័យប័ត្រ',
  policy: 'ប័ណ្ណសន្យារ៉ាប់រង',
  password: 'ពាក្យសម្ងាត់',
  personalInfo: 'ព័ត៌មានផ្ទាល់ខ្លួន',
  account: 'គណនី',
  renewPolicy: 'បន្តបណ្ណសន្យារ៉ាប់រងរបស់អ្នក',
  claim: 'ទាមទារសំណង',
  discount: 'បញ្ចុះតម្លៃ',
  signIn: 'ចូល',
  request: 'ស្នើសុំ',
  update: 'ធ្វើបច្ចុប្បន្នភាព',
  personalInformation: 'ព័ត៌មាន​ផ្ទាល់ខ្លួន',
  edit: 'កែសម្រួល',
  claims: 'ការទាមទារសំណង',
  payNow: 'ទូទាត់ឥលូវនេះ',
  noData: 'គ្មាន​ទិន្នន័យ',
  viewMore: 'មើល​ច្រើន​ទៀត',
  expiryDate: 'កាលបរិច្ឆេទផុតកំណត់',
  policyNumber: 'លេខប័ណ្ណសន្យារ៉ាប់រង',
  orderNumber: 'លេខបញ្ជារទិញ',
  theInsured: 'អ្នកត្រូវបានធានារ៉ាប់រង',
  theInsuredPolicyholder: 'អ្នកត្រូវបានធានារ៉ាប់រង',
  claimForm: 'ពាក្យស្នើសុំទាមទារសំណង',
  insurer: 'ក្រុមហ៊ុនធានារ៉ាប់រង',
  policyType: 'ប្រភេទប័ណ្ណសន្យារ៉ាប់រង',
  yes: 'បាទ/ច៎ា',
  no: 'ទេ',
  yesHave: 'មាន',
  submit: 'ដាក់ស្នើរ',
  learnMore: 'សិក្សារបន្ថែម',
  recommendedReading: 'សូមណែនាំអោយអាន',
  compare: 'ប្រៀបធៀប',
  remove: 'ដកចេញ',
  removeAll: 'លុបទាំងអស់',
  back: 'ត្រលប់ក្រោយ',
  unknown: 'មិនស្គាល់',
  details: 'ព័ត៌មានលម្អិត',
  clickToViewDetails: 'ចុចដើម្បីមើលព័ត៌មានលម្អិត',
  status: 'ស្ថានភាព',
  renew: 'បន្តថ្មី',
  startDate: 'ថ្ងៃ​ចាប់ផ្តើម',
  endDate: 'កាលបរិច្ឆេទបញ្ចប់',
  proceedToPayment: 'បន្តទៅការទូទាត់',
  paySecurely: 'បង់ប្រាក់ដោយសុវត្ថិភាព',
  close: 'បិទ',
  viewDetails: 'ចូលមើលព័ត៌មានលម្អិត',
  coverage: 'ការធានារ៉ាប់រង',
  covers: 'ការធានារ៉ាប់រង',
  deductible: 'កំរិតមិនធ្វើសំណង',
  exclusion: 'ករណីមិនធានារ៉ាប់រង',
  privateCar: 'យានយន្តឯកជន',
  sendNow: 'ផ្ញើរឥលូវនេះ',
  periodOfInsurance: 'រយៈពេលនៃការធារារ៉ាប់រង',
  autoPrivateVehicleInsurance: 'ធានារ៉ាប់រងយានយន្តឯកជន',
  paidDate: 'ថ្ងៃ​បានបង់ប្រាក់',
  declaration: 'សេចក្តីប្រកាស',
  thirdPartyCover: 'ទំនួលខុសត្រូវលើតតិយជនតែប៉ុណ្ណោះ',
  comprehensiveCover: 'ទំនួលខុសត្រូវលើតតិយជន + ការខូចខាតផ្ទាល់ខ្លួន + ចោរកម្ម',
  compareQuotes: 'ប្រៀបធៀបសម្រង់តម្លៃ',
  endorsement: 'ការកែប្រែធានារ៉ាប់រង (Endorsement)',
  change: 'ផ្លាស់ប្តូរ',
  planType: 'ប្រភេទគម្រោង',
  loading: 'កំពុងដំណើរការ',
  quoteDetails: 'សម្រង់តម្លៃលម្អិត',
  youWillPay: 'អ្នកនឹងបង់ប្រាក់ចំនួន',
  planSummary: 'គម្រោងសង្ខេប',
  company: 'ក្រុមហ៊ុន',
  licensedBy: 'ផ្តល់អាជ្ញាបណ្ណដោយ',
  weAccept: 'យើងទទួលយក',
  mgaCoverSummary: 'បង្កើតដោយ MGA Asia Insurance Brokers ដែលជាក្រុមហ៊ុនជើងសាធានារ៉ាប់រងមានអជ្ញាបណ្ណក្នុងប្រទេសកម្ពុជា។',
  others: 'ផ្សេងៗ',
  brokerLicense: {
    label: 'លេខអាជ្ញាបណ្ណ',
    number: '252 SHV',
  },
  year: 'ឆ្នាំ',
  month: 'ខែ',
  policyTerm: 'រយៈពេលធានា',
  premiumPaymentTerm: 'រយៈពេលនៃការបង់បុព្វលាភធានារ៉ាប់រង',
  and: 'និង',
  resendOTP: 'ផ្ញើ OTP ឡើងវិញ',
  enterWrongNumber: 'បញ្ចូលលេខខុស',
  articles: 'អត្ថបទ',
  benefit: 'អត្ថប្រយោជន៍',
  vehicle: 'យានជំនិះ',
  cambodia: 'ប្រទេសកម្ពុជា',
  noImage: 'គ្មានរូបភាព',
  rejectedReason: 'ហេតុផលត្រូវបានបដិសេធ',
  document: 'ឯកសារ',
  documentType: 'ប្រភេទឯកសារ',
  renewable: 'អាចកកើតឡើងវិញបាន។',
  claimable: 'អាចទាមទារបាន។',
  startedAt: 'បានចាប់ផ្តើមនៅ',
  endedAt: 'បានបញ្ចប់នៅ',
  followUs: 'តាមដានបណ្តាញសង្គមរបស់យើង',
  goHome: 'ទៅទំព័រដើម',
  delete: 'លុប',
  photos: 'រូបថត',
  managePhotos: 'គ្រប់គ្រងរូបថត',
  emergencyCall: 'សម្រាប់លេខទំនាក់ទំនងរបស់ក្រុមហ៊ុនធានារ៉ាប់រង {{hotline}} និង<br />លេខទំនាក់ទំនងក្រុមការងារ MGA Cover : 092-777-125 | 016-444-413',
  featuredIn: 'ត្រូវបានចុះផ្សាយនៅក្នុង',
  preview: 'មើលជាមុន',
  latestArticles: 'អត្ថបទថ្មីៗ',
  home: 'ទំព័រដើម',
  getQuote: 'ទទួលសម្រង់តម្លៃ',
  next: 'បន្ទាប់',
  required: 'ទាមទារ',
  buyNow: 'ទិញឥលូវនេះ',
  feature: 'លក្ខណៈពិសេស',
  myself: 'ខ្លួនខ្ញុំផ្ទាល់',
  myAge: 'អាយុ​ខ្ញុំ',
  spouse: 'ប្តី រឺ ប្រពន្ធ',
  spouseAge: 'អាយុប្តី រឺ ប្រពន្ធ',
  father: 'ឪពុក',
  fatherAge: 'អាយុឪពុក',
  mother: 'ម្ដាយ',
  motherAge: 'អាយុម្ដាយ',
  son: 'កូនប្រុស',
  sonAge: 'អាយុកូនប្រុស',
  daughter: 'កូនស្រី',
  daughterAge: 'អាយុកូនស្រី',
  role: 'តួនាទី',
  accountInformation: 'ព​ត៌​មាន​គណនី',
  promotionCode: 'លេខ​កូដ​ប្រូ​ម៉ូ​សិន',
  clientPolicy: 'ប័ណ្ណសន្យារ៉ាប់រងអតិថិជន',
  review: 'ពិនិត្យ',
  summary: 'សង្ខេប',
  compareGuide: 'បន្ថែមសម្រង់តម្លៃរហូតដល់ {{max}} ដើម្បីប្រៀបធៀប',
  promotionDiscount: 'ការបញ្ចុះតម្លៃការផ្សព្វផ្សាយ',
  healthInsurance: 'ធានារ៉ាប់រងសុខភាព',
  contactViaFacebook: 'ទំនាក់ទំនងយើងខ្ញុំតាមរយៈ Facebook',
  contactViaTelegram: 'ទំនាក់ទំនងយើងខ្ញុំតាមរយៈ Telegram',
  telephone: 'លេខទូរសព្ទ',
  sendQuoteToMyEmail: 'ផ្ញើសម្រង់តម្លៃនេះទៅអ៊ុីមែលរបស់ខ្ញុំ',
  me: 'ខ្ញុំ',
  premium: 'បុព្វលាភធានារ៉ាប់រង',
  copied: 'បានចម្លង',
  copyLink: 'ចម្លងតំណ',
  weight: 'ទម្ងន់',
  height: 'កម្ពស់',
  adminFee: 'ថ្លៃសេវារដ្ឋបាល',
  policyWording: 'ប័ណ្ណសន្យារ៉ាប់រងលម្អិត',
  planBrochure: 'ខិត្តប័ណ្ណគំរោង',
  personalAccidentInsurance: 'ធានារ៉ាប់រងគ្រោះថ្នាក់បុគ្គល',
  premiumBreakdown: 'បុព្វលាភធានារ៉ាប់រងលម្អិត',
  thirdPartyCoverPremium: 'បុព្វលាភលើការធានាររ៉ាប់រងតតិយជន',
  ownDamageTheftCoverPremium: 'បុព្វលាភលើការធានារ៉ាប់រងការខូចខាតផ្ទាល់ខ្លួន (OD) / ចោរកម្ម',
  totalPremium: 'បុព្វលាភសរុប',
  totalPremiumKhr: 'បុព្វលាភសរុបគិតជារៀល',
  ownDamageTheftPremium: 'បុព្វលាភការខូចខាតផ្ទាល់ខ្លួន / ចោរកម្ម',
  thirdPartyPremium: 'បុព្វលាភភាគីទីបី',
  accidentToDriverPremium: 'បុព្វលាភគ្រោះថ្នាក់ដល់អ្នកបើកបរ',
  passengerLiabilityPremium: 'បុព្វលាភការទទួលខុសត្រូវអ្នកដំណើរ',
  policyOverview: 'ទិដ្ឋភាពទូទៅនៃបណ្ណសន្យារ៉ាប់រង',
  newClaim: 'ដាក់ពាក្យទាមទារសំណងថ្មី',
  claimedDate: 'កាលបរិច្ឆេទទាមទារសំណង',
  planDetails: 'គម្រោងលំអិត',
  cancerInsurance: 'ធានារ៉ាប់រងជំងឺមហារីក',
  frontlineNote: 'Frontline ចំណាំ',
  note: 'ចំណាំ',
  policyDetails: 'ព័ត៌មានលម្អិតអំពីបណ្ណសន្យារ៉ាប់រង',
  notSet: 'មិនបានកំណត់',
  noteToInsurer: 'ចំណាំទៅក្រុមហ៊ុនធានារ៉ាប់រង',
  fillPolicyDetails: 'បំពេញព័ត៌មានលម្អិតអំពីបណ្ណសន្យារ៉ាប់រង',
  cancel: 'បោះបង់',
  tpl: 'ទំនួលខុសត្រូវរបស់ភាគីទីបី (TPL)',
  coverValueIdv: 'តម្លៃរ៉ាប់រង IDV',
  od: 'ការខូចខាតផ្ទាល់ខ្លួន (OD)',
  theft: 'ចោរកម្ម',
  ad: 'គ្រោះថ្នាក់ដល់អ្នកបើកបរ (AD)',
  pl: 'ទំនួលខុសត្រូវអ្នកដំណើរ (PL)',
  included: 'រួមបញ្ចូល',
  instance: 'ភ្លាមៗ',
  frontlineUser: 'អ្នកប្រើប្រាស់ជួរមុខ',
  policyUnderReview: 'បច្ចុប្បន្ន Policy របស់អ្នកកំពុងស្ថិតក្រោមការត្រួតពិនិត្យ ដោយសារចម្លើយរបស់អ្នក ត្រូវបានតម្រូវអោយមានការវាយតម្លៃដោយអ្នកជំនាញជាមុន។ ដំណើរការនេះនឹងចំណាយពេលប្រហែល បីថ្ងៃ។ យើងនឹងទាក់ទងអ្នកនៅពេលយើងទទួលបានការបញ្ជាក់ពីក្រុមហ៊ុនធានារ៉ាប់រង។',
  enterDetail: 'បញ្ចូលព័ត៌មានលម្អិត',
  specifyProblem: 'សូមបញ្ជាក់បញ្ហា',
  all: 'ទាំងអស់',
  proof: 'ភស្តុតាង',
  corporateInsurance: 'ធានារ៉ាប់រងសាជីវកម្ម',
  information: 'ព័ត៌មាន',
  effectiveDate: 'កាលបរិច្ឆេទមានប្រសិទ្ធភាព',
  member: 'សមាជិក',
  downloadInvoice: 'ទាញយកវិក្កយបត្រ',
  customerConsent: 'ការយល់ព្រមរបស់អតិថិជន',
};
