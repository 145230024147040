import React from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';

import { formatDateTime } from '@utils/datetime';
import { getHotlines, getOrderStatusVariant, Order, OrderStatuses, tOrderableType } from '@models/Order';
import { OrderBadge } from '@components/Base';

import RowItem from '../RowItem';
import { selectIsFrontline } from '@components/Auth';

interface Props {
  order: Order;
  clientPolicy?: boolean;
}

const PolicyItem = ({ order, clientPolicy: isClientPolicy = false }: Props) => {
  const { t } = useTranslation();
  const isFrontline = useSelector(selectIsFrontline);
  const {
    orderableType,
    orderableId,
    orderNumber,
    insuranceType,
    status,
    policyNumber,
    renewable,
    claimable,
    startedAt,
    endedAt,
    insurer,
    activationDate,
    coverScopeTitle,
  } = order;

  const statusVariant = getOrderStatusVariant(status);

  const shouldRenderViewDetails = !includes([OrderStatuses.Pending, OrderStatuses.Expired], status) || activationDate;
  const shouldRenderReviewAndPay = includes([OrderStatuses.Pending, OrderStatuses.Locked], status);
  const shouldRenderPeriod = !!startedAt && !!endedAt;
  const shouldRenderPolicyNumber = !!policyNumber;
  const shouldRenderRenew = renewable && isFrontline;
  const hotline = getHotlines(insurer, insuranceType);

  return (
    <Card border={statusVariant} data-testid="user-dashboard-policy-item">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center bg-white">
        <h5 className="mb-0">{tOrderableType(orderableType)}</h5>
        <OrderBadge status={status} />
      </Card.Header>
      <Card.Body>
        <RowItem label={t('common:orderNumber')} value={orderNumber} />

        {shouldRenderPolicyNumber && <RowItem label={t('common:policyNumber')} value={policyNumber} />}

        <RowItem label={t('common:insurer')} value={insurer.name} />
        <RowItem label={t('common:planType')} value={coverScopeTitle} />

        {shouldRenderPeriod && (
          <RowItem
            label={t('common:periodOfInsurance')}
            value={`${formatDateTime(startedAt)} - ${formatDateTime(endedAt)}`}
          />
        )}

        <Row>
          <Col>
            <Trans i18nKey="common:emergencyCall" values={{ hotline }} />
          </Col>
        </Row>

        <div className="d-flex flex-row mt-3 gap-2">
          {shouldRenderViewDetails && !isClientPolicy && (
            <Button href={useHref(`/user-dashboard/policy/${orderableType}/${orderableId}`)} variant="outline-primary">
              {t('common:viewDetails')}
            </Button>
          )}

          {shouldRenderViewDetails && isClientPolicy && (
            <Button
              href={useHref(`/user-dashboard/client-policy/${orderableType}/${orderableId}`)}
              variant="outline-primary"
            >
              {t('common:viewDetails')}
            </Button>
          )}

          {shouldRenderReviewAndPay && (
            <Button
              href={useHref(`/${insuranceType}-insurance-quote-buy/${orderableId}/review`)}
              variant="outline-primary"
            >
              {isClientPolicy ? t('common:review') : t('policy.actions.reviewAndPay')}
            </Button>
          )}

          {claimable && (
            <Button
              href={useHref(`/user-dashboard/policy/${orderableType}/${orderableId}/claim`)}
              variant="outline-primary"
            >
              {t('common:claim')}
            </Button>
          )}

          {shouldRenderRenew && (
            <Button
              href={useHref(`/user-dashboard/policy/${insuranceType}-insurance/${orderableId}/renewal`)}
              variant="outline-primary"
            >
              {t('common:renew')}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export { PolicyItem };
