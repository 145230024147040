import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, number, object, string, boolean } from 'yup';
import { isEmpty } from 'lodash';
import 'yup-phone-lite';

import { StepType } from '@components/Base';
import { BMI_MAX, BMI_MIN, CHILD_OCCUPATIONS } from '@config';

import { selectHealthQuoteBuyRoute } from '../selector';

export const useSteps = () => {
  const { t } = useTranslation();
  const { healthQuote } = useSelector(selectHealthQuoteBuyRoute);

  return useMemo(() => {
    if (!healthQuote) return;

    const { healthPlanQuestions } = healthQuote;

    const contactAdult = object({
      firstName: string().required(t('validation:required')),
      lastName: string().required(t('validation:required')),
      occupation: string().required(t('validation:required')),
      weight: number().required(t('validation:required')).positive().integer(),
      height: number().required(t('validation:required')).positive().integer(),
      bmi: number().required(t('validation:required')).positive().min(BMI_MIN).max(BMI_MAX),
      dob: string().required(t('validation:required')),
    });

    const contactChild = contactAdult.concat(
      object({
        occupation: string().required(t('validation:required')).oneOf(CHILD_OCCUPATIONS),
      }),
    );

    const questionMember = object({
      medicalQuestionnaire: array()
        .of(
          object({
            question: string(),
            answer: string(),
            remark: string(),
          }),
        )
        .compact((value: { answer: string }) => isEmpty(value?.answer))
        .length(healthPlanQuestions.length),
    });

    const steps: StepType[] = [
      {
        pathname: 'contact',
        validationSchema: object({
          me: contactAdult.concat(
            object({
              gender: string().required(t('validation:required')),
              email: string().email(),
              mobileNumber: string()
                .required(t('validation:required'))
                .phone('KH', t('validation:validPhone', { countryName: t('common:cambodia') })),
              address: string().required(t('validation:required')),
            }),
          ),
          spouse: contactAdult.default(undefined),
          sons: array().of(contactChild),
          daughters: array().of(contactChild),
        }),
      },
      {
        pathname: 'question',
        validationSchema: object({
          isConfirmedDeclaration: boolean().oneOf([true]),
          me: questionMember,
          spouse: questionMember.default(undefined),
          sons: array().of(questionMember),
          daughters: array().of(questionMember),
        }),
      },
    ];

    return steps;
  }, [healthQuote]);
};
