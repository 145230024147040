import { call, put, takeLatest } from 'redux-saga/effects';

import { history } from '@utils/history';
import { CarQuote } from '@models/CarQuote';
import { CarQuoteApi } from '@apis/CarQuoteApi';
import { ProvinceApi } from '@apis/ProvinceApi';
import { CarOrder } from '@models/CarOrder';
import { CarOrderApi } from '@apis/CarOrderApi';
import { SagaPayload } from '@utils/typescript';
import { getCarOrderPaymentPageRoute } from '@components/CarOrderPaymentPage/route';

import {
  carQuoteFetch,
  CarQuoteFetchPayload,
  carQuoteCreate,
  CarQuoteCreatePayload,
  carQuoteEdit,
  CarQuoteEditPayload,
  carQuoteUpdate,
  CarQuoteUpdatePayload,
  carQuoteReset,
  CarQuoteResetPayload,
  carQuoteCustom,
  CarQuoteCustomPayload,
  carQuoteLock,
  CarQuoteLockPayload,
  carQuotePayByCash,
  CarQuotePayByCashPayload,
  carRegistrationPlacesFetch,
  carOrderCreate,
  CarOrderCreateBillingPayload,
  stateUpdate,
  StateUpdatePayload,
} from './action';
import { getCarQuoteBuyReviewPageRoute } from './route';

function* handleCarQuoteFetch({ payload }: SagaPayload<CarQuoteFetchPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.get, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteCreate({ payload }: SagaPayload<CarQuoteCreatePayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.create, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(getCarQuoteBuyReviewPageRoute({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteEdit({ payload }: SagaPayload<CarQuoteEditPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.edit, payload)) as CarQuote;
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarQuoteUpdate({ payload }: SagaPayload<CarQuoteUpdatePayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.update, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(getCarQuoteBuyReviewPageRoute({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteReset({ payload }: SagaPayload<CarQuoteResetPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.reset, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(getCarQuoteBuyReviewPageRoute({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteCustom({ payload }: SagaPayload<CarQuoteCustomPayload>) {
  const state: StateUpdatePayload = { carQuote: null };
  try {
    state.carQuote = (yield call(CarQuoteApi.custom, payload)) as CarQuote;
    yield put(stateUpdate(state));
    history.navigate(getCarQuoteBuyReviewPageRoute({ carOrderId: state.carQuote.carOrderId }));
  } catch { }
}

function* handleCarQuoteLock({ payload }: SagaPayload<CarQuoteLockPayload>) {
  try {
    yield call(CarQuoteApi.lock, payload);
    history.navigate(getCarQuoteBuyReviewPageRoute({ carOrderId: payload.carOrderId }));
  } catch { }
}

function* handleCarQuotePayByCash({ payload }: SagaPayload<CarQuotePayByCashPayload>) {
  try {
    yield call(CarQuoteApi.payByCash, payload);
    history.navigate(getCarQuoteBuyReviewPageRoute({ carOrderId: payload.carOrderId }));
  } catch { }
}

function* handleOrderCreate({ payload }: SagaPayload<CarOrderCreateBillingPayload>) {
  const state: StateUpdatePayload = { carOrder: null };
  try {
    state.carOrder = (yield call(CarOrderApi.createBilling, payload)) as CarOrder;
    yield put(stateUpdate(state));
    history.navigate(
      getCarOrderPaymentPageRoute({ carOrderId: state.carOrder.id, billingId: state.carOrder.billingId }),
    );
  } catch {
    yield put(stateUpdate(state));
  }
}

function* handleCarRegistrationPlacesFetch() {
  const state: StateUpdatePayload = { carRegistrationPlaces: [] };
  try {
    state.carRegistrationPlaces = (yield call(ProvinceApi.getAll)) as string[];
    yield put(stateUpdate(state));
  } catch {
    yield put(stateUpdate(state));
  }
}

export default function* () {
  yield takeLatest(carQuoteFetch, handleCarQuoteFetch);
  yield takeLatest(carQuoteCreate, handleCarQuoteCreate);
  yield takeLatest(carQuoteEdit, handleCarQuoteEdit);
  yield takeLatest(carQuoteUpdate, handleCarQuoteUpdate);
  yield takeLatest(carQuoteReset, handleCarQuoteReset);
  yield takeLatest(carQuoteCustom, handleCarQuoteCustom);
  yield takeLatest(carQuoteLock, handleCarQuoteLock);
  yield takeLatest(carQuotePayByCash, handleCarQuotePayByCash);
  yield takeLatest(carOrderCreate, handleOrderCreate);
  yield takeLatest(carRegistrationPlacesFetch, handleCarRegistrationPlacesFetch);
}
