import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HeaderSecondary from '@components/HeaderSecondary';

import CarQuoteBuyEditPage from './CarQuoteBuyEditPage';
import CarQuoteBuyNewPage from './CarQuoteBuyNewPage';
import CarQuoteBuyReviewPage from './CarQuoteBuyReviewPage';
import CarQuoteBuyResetPage from './CarQuoteBuyResetPage';
import CarQuoteBuyCustomPage from './CarQuoteBuyCustomPage';
import CarQuoteBuyLockPage from './CarQuoteBuyLockPage';
import CarQuoteBuyPayByCashPage from './CarQuoteBuyPayByCashPage';

const CarQuoteBuyRoute = () => {
  return (
    <>
      <HeaderSecondary />
      <Routes>
        <Route index element={<CarQuoteBuyNewPage />} />
        <Route path="/:carOrderId/edit" element={<CarQuoteBuyEditPage />} />
        <Route path="/:carOrderId/review" element={<CarQuoteBuyReviewPage />} />
        <Route path="/:carOrderId/reset" element={<CarQuoteBuyResetPage />} />
        <Route path="/:carOrderId/custom" element={<CarQuoteBuyCustomPage />} />
        <Route path="/:carOrderId/lock" element={<CarQuoteBuyLockPage />} />
        <Route path="/:carOrderId/pay-by-cash" element={<CarQuoteBuyPayByCashPage />} />
      </Routes>
    </>
  );
};

export { CarQuoteBuyRoute };
