import { stringify } from 'qs';

import {
  CancerQuoteDetailsPageParams,
  cancerQuoteDetailsPageParamsSchema,
} from '@components/CancerQuoteDetailsRoute/route';
import { replaceRouteParams } from '@utils/route';

export type CancerQuoteBuyNewPageParams = CancerQuoteDetailsPageParams;

export type CancerQuoteBuyEditPageParams = {
  cancerQuoteId: number;
};

export type CancerQuoteBuyReviewPageParams = CancerQuoteBuyEditPageParams;

export type CancerQuoteBuyCustomPageParams = {
  cancerOrderId: number;
};

export type CancerQuoteSendEmailPageParams = CancerQuoteDetailsPageParams;

export const cancerQuoteBuyNewPageParamsSchema = cancerQuoteDetailsPageParamsSchema;

export const CANCER_QUOTE_BUY_ROUTE_URL = '/cancer-insurance-quote-buy/*';

export const CANCER_QUOTE_BUY_NEW_PAGE_URL = '/cancer-insurance-quote-buy';

export const CANCER_QUOTE_BUY_EDIT_PAGE_URL = '/cancer-insurance-quote-buy/:cancerQuoteId/edit';

export const CANCER_QUOTE_BUY_REVIEW_PAGE_URL = '/cancer-insurance-quote-buy/:cancerQuoteId/review';

export const CANCER_QUOTE_SEND_MAIL_PAGE = '/cancer-insurance-quote-details/send-email';

export const CANCER_QUOTE_BUY_CUSTOM_PAGE_URL = '/cancer-insurance-quote-buy/:cancerOrderId/custom';

export const getCancerQuoteBuyNewPageRoute = (params: CancerQuoteBuyNewPageParams) => {
  return {
    pathname: CANCER_QUOTE_BUY_NEW_PAGE_URL,
    search: stringify(params),
  };
};

export const getCancerQuoteBuyEditPageRoute = (params: CancerQuoteBuyEditPageParams) => {
  return {
    pathname: replaceRouteParams(CANCER_QUOTE_BUY_EDIT_PAGE_URL, params),
  };
};

export const getCancerQuoteBuyReviewPageRoute = (params: CancerQuoteBuyReviewPageParams) => {
  return {
    pathname: replaceRouteParams(CANCER_QUOTE_BUY_REVIEW_PAGE_URL, params),
  };
};

export const getCancerQuoteSendEmailPageRoute = (params: CancerQuoteSendEmailPageParams) => {
  return {
    pathname: CANCER_QUOTE_SEND_MAIL_PAGE,
    search: stringify(params),
  };
};

export const getCancerQuoteBuyCustomPageRoute = (params: CancerQuoteBuyCustomPageParams) => {
  return {
    pathname: replaceRouteParams(CANCER_QUOTE_BUY_CUSTOM_PAGE_URL, params),
  };
};
