import React, { ReactElement } from 'react';
import { Row, Col, Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { formatDate } from '@utils/datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { OrderBadge } from '@components/Base';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';

interface RowItemProps {
  label: string;
  value?: string | number | ReactElement;
}

export const RowItem = ({ label, value }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        {label}
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const PolicySummaryCardBody = ({ status, orderNumber, activationDate }: PersonalAccidentQuote) => {
  const { t } = useTranslation();

  const isValidActivationDate = moment(activationDate).isAfter(moment(), 'day');

  return (
    <Card.Body>
      <RowItem label={t('common:status')} value={<OrderBadge {...{ status }} />} />
      <RowItem label={t('common:orderNumber')} value={orderNumber} />
      <RowItem label={t('common:policyEffectiveDate')} value={formatDate(activationDate)} />

      {!isValidActivationDate && (
        <Alert variant="danger">
          <Alert.Heading className="d-flex align-items-center">
            <FontAwesomeIcon icon={faTriangleExclamation} size="lg" className="me-2" />
            {t('alert.premiumChange.title')}
          </Alert.Heading>

          <p>{t('alert.premiumChange.effectiveDate')}</p>
        </Alert>
      )}
    </Card.Body>
  );
};

export { PolicySummaryCardBody };
