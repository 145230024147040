import React from 'react';
import { Row, Col, Card, Accordion, Stack, Image } from 'react-bootstrap';
import { map, slice } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { HealthQuoteGroup } from '@models/HealthQuoteGroup';
import { HealthQuote } from '@models/HealthQuote';

import { HealthQuoteItem } from './health-quote-item';

const HealthQuoteGroupItem = ({ insurer, healthQuotes }: HealthQuoteGroup) => {
  const { t } = useTranslation();
  const mainQuote = healthQuotes[0];
  const moreQuotes: HealthQuote[] = slice(healthQuotes, 1);
  const shouldRenderMoreQuotes = moreQuotes.length > 0;

  return (
    <Card className="shadow">
      <Card.Body>
        <Row className="g-3">
          <Col lg={2}>
            <Stack direction="horizontal" gap={2} className="justify-content-center">
              <Image src={insurer.logo} className="rounded img-fluid" />
            </Stack>
          </Col>
          <Col lg={10}>
            <HealthQuoteItem healthQuote={mainQuote} />
          </Col>
        </Row>
      </Card.Body>
      {shouldRenderMoreQuotes && (
        <Card.Body>
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Row>
                <Col xs={6} md={2} className="mx-auto">
                  <Accordion.Header className="w-100">
                    {t('healthQuote.viewMore', { count: moreQuotes.length })}
                  </Accordion.Header>
                </Col>
              </Row>
              <Accordion.Body className="mt-3 px-0">
                {map(moreQuotes, (quote, index) => {
                  const shouldRenderBorderBottom = moreQuotes.length > 1 && index !== moreQuotes.length - 1;

                  return (
                    <Row key={quote.healthPlanId} className="mb-4">
                      <Col
                        lg={{ span: 10, offset: 2 }}
                        className={classNames('pb-4', { 'border-bottom': shouldRenderBorderBottom })}
                      >
                        <HealthQuoteItem healthQuote={quote} />
                      </Col>
                    </Row>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      )}
    </Card>
  );
};

export { HealthQuoteGroupItem };
