import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, boolean, object, string, lazy } from 'yup';
import { isLoggedIn } from 'axios-jwt';
import moment from 'moment-timezone';
import { includes, range } from 'lodash';

import { selectCurrentUser } from '@components/Auth';
import { useParseParams } from '@hooks/search-params';
import { getAge } from '@utils/datetime';
import { mergeInitialValues } from '@utils/formik';
import { ADULT_AGE_MAX, ADULT_AGE_MIN, CHILD_AGE_MAX, EFFECTIVE_DAY_MIN, EFFECTIVE_DAY_MAX } from '@config';
import { StepType } from '@components/Base';
import { Genders } from '@models/User';

import { FormValues } from './type';

const useFormValuesParamsSchema = () => {
  const loggedIn = isLoggedIn();

  return useMemo(() => {
    let schema = object({
      me: object({
        checked: boolean().default(true),
        dob: string().default(''),
        gender: string().default(Genders.Male),
      }),
      spouse: object({
        checked: lazy((value, { parent }) => boolean().default(!!parent?.dob)),
        dob: string().default(''),
      }).default(undefined),
      sons: array().of(
        object({
          dob: string().default(''),
        })
      ).default(undefined),
      daughters: array().of(
        object({
          dob: string().default(''),
        })
      ).default(undefined),
      activationDate: string().default(''),
    });

    if (!loggedIn) {
      schema = schema.concat(
        object({
          mobileNumber: string().default(''),
          fullName: string().default(''),
        })
      );
    }

    return schema;
  }, [loggedIn]);
}

const useFormValuesParams = () => {
  const schema = useFormValuesParamsSchema();
  return useParseParams<FormValues>(schema);
};

export const useInitialValues = () => {
  const params = useFormValuesParams();
  const currentUser = useSelector(selectCurrentUser);

  return useMemo(() => {
    if (!currentUser) return params;

    const { gender, dob } = currentUser;

    return mergeInitialValues(params, {
      me: {
        gender,
        dob,
      },
    });
  }, [currentUser]);
};

export const useSteps = () => {
  const { t } = useTranslation();
  const loggedIn = isLoggedIn();

  return useMemo<StepType[]>(() => {
    const DATE_FORMAT = 'MM/DD/YYYY';

    const childBirthDateMin = moment().subtract(CHILD_AGE_MAX, 'years').format(DATE_FORMAT);
    const child = object({
      dob: string()
        .required(t('validation:required'))
        .test(
          'min',
          t('validation:minDate', { minDate: childBirthDateMin }),
          (dob) => Number(getAge(dob)) <= CHILD_AGE_MAX
        )
    });

    const adultBirthDateMin = moment().subtract(ADULT_AGE_MAX, 'years').format(DATE_FORMAT);
    const adultBirthDateMax = moment().subtract(ADULT_AGE_MIN, 'years').format(DATE_FORMAT);
    const adultDobSchema = string()
      .required(t('validation:required'))
      .test(
        'range',
        t('validation:range', { min: adultBirthDateMin, max: adultBirthDateMax }),
        (dob) => includes(range(ADULT_AGE_MIN, ADULT_AGE_MAX), Number(getAge(dob)))
      )

    const effectiveDateMin = moment().add(EFFECTIVE_DAY_MIN, 'day').format(DATE_FORMAT);
    const effectiveDateMax = moment().add(EFFECTIVE_DAY_MAX, 'day').format(DATE_FORMAT);

    let steps: StepType[] = [
      {
        pathname: 'members',
        validationSchema: object({
          me: object({
            checked: boolean().oneOf([true]),
            gender: string().required(t('validation:required')),
          }),
        }),
      },
      {
        pathname: 'birthdates',
        validationSchema: object({
          me: object({
            dob: adultDobSchema
          }),
          spouse: object({
            checked: boolean(),
            dob: string().when('checked', {
              is: true,
              then: (schema) => {
                return schema
                  .required(t('validation:required'))
                  .test(
                    'range',
                    t('validation:range', { min: adultBirthDateMin, max: adultBirthDateMax }),
                    (dob) => includes(range(ADULT_AGE_MIN, ADULT_AGE_MAX), Number(getAge(dob)))
                  )
              },
            }),
          }),
          sons: array().of(child),
          daughters: array().of(child),
        }),
      },
      {
        pathname: 'policy-effective-date',
        validationSchema: object({
          activationDate: string()
            .required(t('validation:required'))
            .test(
              'range',
              t('validation:range', { min: effectiveDateMin, max: effectiveDateMax }),
              (activationDate) => includes(range(EFFECTIVE_DAY_MIN - 1, EFFECTIVE_DAY_MAX), moment(activationDate).diff(moment(), 'days'))
            )
        }),
      },
    ];

    if (!loggedIn) {
      steps = [
        ...steps,
        {
          pathname: `user`,
          validationSchema: object({
            mobileNumber: string().required(t('validation:required')).phone('KH'),
            fullName: string().required(t('validation:required')),
          }),
        },
      ]
    }

    return steps;
  }, [loggedIn]);
};
