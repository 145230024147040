import React from 'react';
import MetaTag from '@components/MetaTag';
import HeaderPrimary from '@components/HeaderPrimary';
import Footer from '@components/Footer';
import SupportSection from '@components/SupportSection';
import CallToActionSection from './CallToActionSection';
import DefinitionSection from './DefinitionSection';
import WhyNeedSection from './WhyNeedSection';
import CoverSection from './CoverSection';
import { NoCoverSection } from './no-cover-section';
import { ExtraBenefitSection } from './extra-benefit-section';
import { EligibilitySection } from './eligibility-section';
import { WhoShouldGetSection } from './who-should-get-section';
import { FrequentlyAskedQuestionSection } from './frequently-asked-question-section';
import { DisclaimerSection } from './disclaimer-section';

export const PersonalAccidentInsurancePage = () => {
  return (
    <>
      <MetaTag pageName="personalAccidentInsurancePage" />
      <HeaderPrimary />
      <CallToActionSection />
      <DefinitionSection />
      <WhyNeedSection />
      <CoverSection />
      <NoCoverSection />
      <ExtraBenefitSection />
      <EligibilitySection />
      <WhoShouldGetSection />
      <FrequentlyAskedQuestionSection />
      <SupportSection />
      <DisclaimerSection />
      <Footer />
    </>
  );
};
