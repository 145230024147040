import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { CancerQuote } from '@models/CancerQuote';
import { DocumentItem } from './benefit-card-body';

interface RowItemProps {
  label: string;
  value?: string | number;
}

const RowItem = ({ label, value }: RowItemProps) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        {label}
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const FeatureCardBody = ({ geographicCoverage, hospitals, note, proof }: CancerQuote) => {
  const { t } = useTranslation();

  return (
    <Card.Body>
      <Card.Title>{pluralize(t('common:feature'), 2)}</Card.Title>
      <RowItem label={t('cancerQuote.geographicCoverage')} value={geographicCoverage} />
      <RowItem label={t('cancerQuote.cashlessHospitals')} value={hospitals.length} />
      <RowItem label={t('common:note')} value={note} />
      {proof && <RowItem label="" value={<DocumentItem name="Download Proof" fileUrl={proof} />} />}
    </Card.Body>
  );
};

export { FeatureCardBody };
