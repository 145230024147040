import { object, number, array, string, mixed } from 'yup';
import i18n from '@components/i18n';
import { includes } from 'lodash';

const FILE_SIZE = 5_000_000; //5MB
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const formValuesParamsSchema = object({
  proof: mixed()
    .optional()
    .test('fileSize', i18n.t('validation:fileSizeOutOfRange'), (file: File) => !file || (file && file.size < FILE_SIZE))
    .test(
      'fileFormat',
      i18n.t('validation:invalidFormat'),
      (file: File) => !file || (file && includes(SUPPORTED_FORMATS, file.type)),
    ),
  note: string().required(),
  me: object({ chargeAmount: number() }),
  spouse: object({ chargeAmount: number() }),
  sons: array().of(object({ chargeAmount: number() })),
  daughters: array().of(object({ chargeAmount: number() })),
});
