import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { isEmpty } from 'lodash';

import { HealthQuote } from '@models/HealthQuote';
import { HealthPlanBenefit, HealthPlanBenefitTypes, tHealthPlanBenefitType } from '@models/HealthPlanBenefit';
import { amountToUsd } from '@utils/currency';
import { NumericString } from '@utils/typescript';

import { Title } from './title';

const RowItem = ({ label, value }: { label: string; value?: React.ReactNode }) => {
  return (
    <Row className="mb-2">
      <Col xs={6} sm={6} md={6}>
        <strong>{label}</strong>
      </Col>
      <Col>
        <div className="d-flex justify-content-end">{value}</div>
      </Col>
    </Row>
  );
};

const BenefitItem = ({
  benefitType,
  amount,
  items,
}: {
  benefitType: HealthPlanBenefitTypes;
  amount: NumericString;
  items: HealthPlanBenefit[];
}) => {
  if (isEmpty(items)) return null;
  if (!Number(amount)) return null;

  return <RowItem label={tHealthPlanBenefitType(benefitType)} value={amountToUsd(amount)} />;
};

const FeatureSection = ({
  geographicCoverage,
  hospitals,
  inpatientAnnualLimitAmount,
  inpatientBenefits,
  outpatientAnnualLimitAmount,
  outpatientBenefits,
  healthCheckupAnnualLimitAmount,
  healthCheckupBenefits,
  maternityAnnualLimitAmount,
  maternityBenefits,
  visionAnnualLimitAmount,
  visionBenefits,
  dentalAnnualLimitAmount,
  dentalBenefits,
  copayAnnualLimitAmount,
  copayBenefits,
  othersAnnualLimitAmount,
  othersBenefits,
}: HealthQuote) => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Title>{pluralize(t('common:feature'), 2)}</Title>

      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
            <Row className="g-3">
              <Col sm={12} md={6}>
                <Card className="h-100">
                  <Card.Body>
                    <RowItem label={t('healthQuote.geographicCoverage')} value={geographicCoverage} />
                    <RowItem label={t('healthQuote.cashlessHospitals')} value={hospitals.length} />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="h-100">
                  <Card.Body>
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Inpatient}
                      amount={inpatientAnnualLimitAmount}
                      items={inpatientBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Outpatient}
                      amount={outpatientAnnualLimitAmount}
                      items={outpatientBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.HealthCheckup}
                      amount={healthCheckupAnnualLimitAmount}
                      items={healthCheckupBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Maternity}
                      amount={maternityAnnualLimitAmount}
                      items={maternityBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Vision}
                      amount={visionAnnualLimitAmount}
                      items={visionBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Dental}
                      amount={dentalAnnualLimitAmount}
                      items={dentalBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Copay}
                      amount={copayAnnualLimitAmount}
                      items={copayBenefits}
                    />
                    <BenefitItem
                      benefitType={HealthPlanBenefitTypes.Others}
                      amount={othersAnnualLimitAmount}
                      items={othersBenefits}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { FeatureSection };
