import React from 'react';
import { Col, Row, Card, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { SUPPORTS_WITHOUT_EMAIL } from '@components/Feature/Support/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SupportSection = () => {
  const { t } = useTranslation();

  return (
    <section className="py-5">
      <Container fluid="md">
        <Card body className="p-3" text="white" bg="primary">
          <Row className="align-items-center ">
            <Col xs className="mb-4 mb-md-0 text-center text-md-start">
              <Card.Title className="fw-bold">{t('supportCard.header')}</Card.Title>
              <Card.Text>{t('supportCard.subHeader')}</Card.Text>
            </Col>

            <Col xs={12} md={6}>
              <Row xs={{ cols: 1 }} sm={{ cols: 2 }} className="gx-1 gy-1">
                {map(SUPPORTS_WITHOUT_EMAIL, ({ value, href, icon, label, type }) => (
                  <Col key={value}>
                    <Button
                      variant="light"
                      href={href}
                      target={type === 'social' ? '_blank' : undefined}
                      className="w-100"
                    >
                      <FontAwesomeIcon icon={icon} className="fa-fw me-2" />
                      {label}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    </section>
  );
};

export { SupportSection };
