// 2025-01-03 11:27:41 +0700

export default {
  banner: {
    header1: 'Get a Quote, Compare, Buy.\nAll in Minutes with MGA Cover.',
    header2: 'GET FREE CAR INSURANCE QUOTES',
    subHeader: 'Get instant quotes from our top partner car insurance companies and compare now.',
    cta: 'GET QUOTES',
  },
  mga: {
    address: 'Building No. 466, Mao Tse Toung Street (245), Corner of Street 150, Phnom Penh, Cambodia',
  },
  supportCard: {
    header: 'Need help? Contact us now',
    subHeader: 'Our experts will always help you!',
  },
  faq: {
    header: 'Frequently Asked Questions',
    q1: 'What does MGA Cover do?',
    a1: 'MGA Cover is an Insurtech company that digitalises insurance products from most trusted insurance companies in the kingdom of Cambodia.  \n\nThrough www.mgacover.com, you can quickly view premiums, compare premiums, plan details, and terms and conditions of policies of each insurance company in less than one minute. You can also buy an insurance policy and pay online securely and get protected instantly. Moreover, you are able to file a claim and renew your policy online as well.',
    q2: 'What is Car Insurance?',
    a2: 'Car insurance is also known as auto insurance or vehicle insurance. Car insurance provides financial protection to your car from unexpected or accidental risks. In exchange for your paying a premium, the insurance company agrees to pay your losses as outlined in your policy.',
    q3: ' What is a Car Insurance Policy?',
    a3: 'Car insurance policy is a contract between yourself and an insurance company in which you agree to pay premiums in exchange for protection against financial losses arising from an accident or other damage to the car. Every policy is based on limits, or the maximum amount the insurance company will pay on a claim.',
    q4: 'What are the types of car insurance?',
    a4: 'There are two types of car insurance:\n\n1) Third-Party Liability (TPL)\nThird-party car insurance, also known as liability insurance, covers damages incurred to a third person or property. It does not provide cover for you and your car.\n\n2) Comprehensive/Standard\nA comprehensive car insurance covers against loss or damage to your own car or damage to others\' vehicle or property or injuries to a third-party.',
    q5: 'What does a car insurance policy cover?',
    a5: 'Third-Party Liability covers against:\n- Death of or bodily injury to any person\n- Damage to third-party\'s property\n- Legal service\n\nComprehensive/Standard covers against:\n- Third-Party Liability\n- Own Damage - indemnity for damage to your own car due to accident\n- Theft - indemnity for loss of your own whole car as result of theft',
    q6: 'What are car insurance add-on covers?',
    a6: 'Add-on covers are extended covers or benefits to basic covers.\n- Accident to Driver\nThis cover provides indemnity for the death or permanent disablement or bodily injury to any person driving the car at the time of the occurrence of the event out of which any claim arises.\n\n- Passenger Liability\nThis cover provides indemnity for the death of or bodily injury to any person being carried in or entering or getting on to or getting off the motor vehicle at the time of the occurrence of the event out of which any claim arises.',
    q7: ' Why should I buy car insurance?',
    a7: 'Even though it is not mandatory in the kingdom of Cambodia yet, you should have at least a car insurance third-party liability cover when you own a private car.\n\nA car insurance policy provides you with financial liabilities that may arise towards a third-party or loss or own-damages to your car due to a road accident or any other unfortunate event.',
    q8: 'Why should I buy a car insurance policy online?',
    a8: 'Like common online purchases, Car Insurance Policy Online purchase has become a common practice today. You can get your car insured against damages and financial losses caused to accident, fire, theft etc. in less than 5 minutes. Check out the below advantages that come with purchasing insurance online. \n\n- Convenient and Time Effective\n- Transparency \n- Cost-effective\n- Pay Premium Online',
    q9: 'How do I buy a car insurance policy?',
    a9: 'We make car insurance simple and convenient for you. You can buy your car insurance policy within few click by visiting our website mgacover.com  and following the steps mentioned below:\n\n1. Answer a few simple questions about your car like car brand, model, engine number and year then you get a car insurance quotation\n\n2. View quotes and do quote comparison with detailed information\n\n3. Choose your favorite plan\n\n4.  Create an account with us, and pay online securely\n\nOnce the payment is made, you will get an email notification and account to login to the customer dashboard. You can view payment transactions and your car insurance policy details and download documents in PDF.',
    q10: ' How long does it take to buy car insurance on MGA Cover?',
    a10: 'It is quick in less than 5 minutes to buy your car insurance policy on MGA Cover. In case you need assistance, our support team is always available on call or chat to assist you.',
    q11: 'Am I immediately protected after a successful car insurance policy purchase?',
    a11: 'Yes. You are instantly protected after a successful purchase.',
    q12: 'What are the criterias that insurance companies consider to accept car insurance applications?',
    a12: 'Most insurance companies ask you to provide various relevant information about your private car such as make, model, variant, IDV (known as approximate market value), engine capacity and so on, your personal details, and other aspects so that they can consider accepting or rejecting your application.',
    q13: ' How to make a car insurance claim online?',
    a13: 'You can submit a claim online in MGA Cover user dashboard by the following easy steps:\n1. Sign in your account on MGA Cover website\n2. In your user dashboard, view all your policies\n3. Click on button Claim on the policy you want to file a claim\n4. Fill in required details and then submit your claim\n5. You can track your claim status that you have submitted by viewing the details in the Claim menu',
    q14: 'Can I compare car insurance policies among insurance companies?',
    a14: 'Of course. MGA Cover platform allows you to compare premiums, coverages, and other details of at most 3 quotes from insurance companies per comparison. Thus, it is convenient for you to make a decision on the right plan and budget.',
  },
  testimonial: {
    header: 'Statement of our customers',
  },
  partner: {
    header: 'Our popular partner insurance company',
  },
  howItWorks: {
    header: 'How does the MGA Cover work?',
    title1: 'Fill in your details',
    desc1: 'Fill in your details and get insurance premium quotes from our trusted partner insurance companies right away.',
    title2: 'Compare quotes and choose your preferred plan',
    desc2: 'Choose the quote that best suits your needs and budget.',
    title3: 'Make payment and Get Protected',
    desc3: 'Pay online via our secure payment method and get an instant cover.',
  },
  benefit: {
    header: 'Why choose MGA Cover?',
    title1: 'Instant Quote & Cover',
    desc1: 'Fast, convenient, and 100% digital. View instant quotes of various insurance companies in just less than 2 minutes. Get an instant cover in just less than 5 minutes.',
    title2: 'Affordable Prices',
    desc2: 'Purchasing insurance should not be a financial burden for you. We offer the right insurance plan for your budget.',
    title3: 'Seamless Claims',
    desc3: 'Our goal is to make insurance claims easier. All you have to do is to submit your claim online and experience cashless, quick, and easy claim settlements. We will be with you every step of the way.',
  },
  registration: {
    title: 'Sign in to Your MGA Cover Account',
    desc: 'To sign in, please enter your mobile number',
    mobileNumberLabel: 'Enter mobile number',
    ctaOtp: 'Sign in with OTP Code',
    ctaPassword: 'Sign in with Password',
    ctaCamDx: 'Sign in with Camdigikey',
    otpCode: {
      title: 'Sign in to Your MGA Cover Account',
      desc: 'Enter the code we sent to the mobile number',
    },
    password: {
      title: 'Sign in to Your MGA Cover Account',
      desc: 'Enter the password for mobile number',
    },
    continueWith: 'Or Continue With',
  },
  paymentMethod: {
    paywayTitle: 'ABA Payway',
    paywayShortDesc: 'Pay via QR Code scan or credit/debit card',
    acledaTitle: 'ACLEDA Bank Plc.',
    acledaShortDesc: 'Pay securely with ACLEDA Bank Plc.',
  },
  carInsuranceQuote: {
    carBrand: {
      title: 'What is your car\'s brand?',
    },
    carModel: {
      title: 'Which {{carBrandName}} car?',
    },
    carSubModel: {
      title: 'Which {{carModelName}} variant do you drive?',
    },
    carManufacturedYear: {
      title: 'What is the year of manufacture?',
    },
    thirdPartyPlanDeductible: {
      desc: 'Each and every claim applicable to third-party property damage only',
    },
    ownDamageDeductibleDesc: 'Each and every claim applicable to own damage',
    theftDeductible: {
      desc: '5% of loss amount subject to minimum',
    },
    deathBodilyInjury: 'Death / Bodily injury',
    propertyDamage: 'Property damage',
    costOfDefense: 'Cost of defense',
    ownDamageTheft: 'Own Damage (OD) / Theft',
    annualAggregateLimit: 'Annual Aggregate Limit',
    learnMore: 'Learn more CLAUSES/WARRANTIES/ENDORSEMENTS',
    readDetailAttachment: 'Read detail in the attachments below',
    disabledTitle: 'Quote unavailable',
    disabledDesc: 'This Quote is longer not available. There are many others quotes that you might interested.',
    basicCovers: 'Basic Covers',
    addons: 'Addon Covers',
    accidentToDriver: {
      name: 'Accident to Driver',
      description: 'This cover provides indemnity for the death or permanent disablement or bodily injury to any person driving the car at the time of the occurrence of the event out of which any claim arises. Authorized Driver refers to any person who drives your car on your order or permission but only if the person has a valid driving license in Cambodia and is not under supervision or disqualification from driving.',
    },
    passengerLiability: {
      name: 'Passenger Liability',
      description: 'This cover provides indemnity for the death of or bodily injury to any person being carried in or entering or getting on to or getting off the motor vehicle at the time of the occurrence of the event out of which any claim arises.',
    },
    flood: {
      name: 'Flood',
      description: 'This cover provides indemnity for any damage or loss of your car due to floods.',
    },
    srcc: {
      name: 'Strike, Riot and Civil Commotion (SRCC)',
      description: 'This cover provides indemnity for any damage or loss of your car due to strike, riot, or civil commotion.',
    },
    discounts: {
      title: 'Discounts',
      basicDiscount: 'Basic Discount',
    },
    totalPremium: 'Total Premium',
    endorsement: 'Endorsement',
    endorsementDescription: 'An insurance endorsement is an amendment or addition to an existing insurance contract that changes the terms or scope of the original policy. Any endorsement which applies to your policy is shown in the schedule or the certificate of insurance.',
    deductibleTitle: 'Deductible',
    deductibleDescription: 'Deductible is the amount you must contribute to each and every claim.',
    thirdPartyDeductible: 'Third-Party Property Damage: {{amount}}',
    ownDamageDeductible: 'Own Damage (also applicable to contructive total loss): {{amount}}',
    thiefDeductible: 'Theft: {{amount}} of loss',
    planDetails: 'Plan Details',
    coversOn: 'Covers on {{planTypeName}}',
    policyWording: 'Policy Wording',
    repairAnywhere: 'Repair anywhere across in Cambodia',
    freeService: 'Free pick-up and drop service at time of claim for repair at insurer approved workshops.',
    cashlessGarages: 'Cashless Garages',
    garageNearYou: 'Cashless Garages near you',
    compareTitle: 'Compare quotes',
    compareDescription: 'Compare prices, covers, exclusions, deductibles, and more.',
    garagesCount: '{{garagesCount}} cashless garages',
    policyDetail: 'Policy Details',
    premium: 'Premium',
    plansTitle: '{{quotesCount}} {{planTypeName}} plans',
    insuredDeclaredValue: {
      modalTitle: 'Set Your Car\'s Cover Value (IDV)',
      lowestAmount: 'Lowest Possible IDV ({{amount}})',
      recommendedAmount: 'Recommended IDV ({{amount}})',
      customAmount: 'Set Your Own IDV',
      insurerCarPrice: 'Insurer\'s Car Price',
      note: 'Please enter an IDV between {{lowestAmount}} and {{highestAmount}}.',
      noteWithoutRecommendedAmount: 'Please enter an accurate IDV of your car. Your IDV must be an approximate market value. Insurance company reserves right to cancel the contract if IDV is considerably below market value.',
      emptyIdvWarningHeader: 'Your car\'s cover value (IDV) is empty!',
      emptyIdvWarningDesc: 'You cannot view quotes when IDV is empty. Please select a lowest or recommended IDV or enter your own accurate IDV of your car.',
      whatIdv: 'What is IDV?',
      description: 'Insured Declared Value (IDV) is the maximum or highest amount you will receive from the insurer in case of full damage or theft of your car. Basically, it is an approximate current market value of your car.',
      title: 'Insured Value (IDV)',
      selectIdv: 'Select your IDV',
      selectIdvNotApplicable: 'Not Applicable',
    },
    planType: {
      comprehensive: {
        name: 'Comprehensive/Standard',
        description: 'This insurance plan covers against loss or damage to your own car or damage to others’ vehicle or property or injuries to a third-party. This includes:',
        coverage1: 'Third-Party Liability',
        coverage2: 'Own Damage - indemnity for damage to your own car due to accident',
        coverage3: 'Theft - indemnity for loss of your own whole car as result of theft',
      },
      thirdParty: {
        name: 'Third-Party',
        description: 'This insurance plan covers against all sums including claimant’s costs and expenses which you shall become legally liable to pay in respect of:',
        coverage1: 'Death of or bodily injury to any person',
        coverage2: 'Damage to third-party’s property',
        coverage3: 'Legal service',
        question: 'What is Third-Party?',
        answer: 'Anyone else who is not a party of the contract',
      },
    },
  },
  confirmModal: {
    title: 'Please confirm!',
    desc: 'Are you sure you want to proceed with your action?',
    confirm: 'Continue',
    cancel: 'Dismiss',
  },
  car: {
    brand: 'Brand',
    model: 'Model',
    subModel: 'Sub model',
    yearOfManufacture: 'Year of manufacture',
    registrationPlace: 'Registration place',
    registrationNumber: 'Registration number',
    activationDate: 'Activation date',
    chassisNumber: 'Chassis number',
    engineNumber: 'Engine number',
    engineCapacity: 'Engine capacity',
    fuelType: 'Fuel type',
    identificationCard: 'Car identification card',
  },
  carInsuranceClaimForm: {
    vehicleInsured: {
      title: 'Vehicle insured',
    },
    accidentDetails: {
      title: 'Details of accident',
    },
    accidentHappenedAt: {
      label: 'Date and time of accident',
    },
    accidentPhotos: {
      label: 'Please attach photos of accident (JPEG, JPG, PNG, or PDF format)',
    },
    passengersThirdParties: {
      title: 'Passengers and third parties',
    },
    passengersAndThirdPartiesPoliceReport: {
      label: 'Please attach a police report (JPEG, JPG, PNG, or PDF format)',
    },
    acceptTermsAndConditions: 'Accept Terms & Conditions. I/We declare that all the answers are true and complete. I/We request you to deal on my/our behalf with the third-party claims arising herein in accordance with the Terms and Conditions of the above-mentioned Policy as well as the laws in force of Cambodia, and I/We authorize you and your attorney at law on my/our behalf to settle this case by any means that you consider as necessary.',
    completedClaimForm: {
      title: 'Completed Claim Form',
      label: 'Please attach a completed claim form (JPEG, JPG, PNG, or PDF format)',
    },
    supportingDocuments: {
      title: 'Supporting Documents',
      label: 'Please attach supporting documents (JPEG, JPG, PNG, or PDF format)',
    },
  },
  carInsuranceClaim: {
    accidentPhotos: 'Photos of accident',
    policeReport: 'Police report',
    completedClaimForm: 'Completed claim form',
    supportingDocuments: 'Supporting documents',
  },
  dashboard: {
    header: 'My Insurance Cards',
  },
  policyDetails: {
    title: 'Policy Details',
    coverageSummaryEn: 'Cover Note in English',
    coverageSummaryKm: 'Cover Note in Khmer',
    policyWording: 'Policy Wording',
    policySchedule: 'Policy Schedule',
    certificateInsurance: 'Certificate of Insurance',
    claimProcedure: 'Claim Procedure',
    certificateOfInsuranceEn: 'Certificate Of Insurance in English',
    certificateOfInsuranceKm: 'Certificate Of Insurance in Khmer',
    invoiceEn: 'Invoice in English',
    invoiceKm: 'Invoice in Khmer',
  },
  policy: {
    actions: {
      reviewAndPay: 'Review & Pay',
    },
  },
  billing: {
    receiptTitle: 'Receipt',
  },
  insuranceTypes: {
    car: 'Car',
    health: 'Health',
    life: 'Life',
    property: 'Property',
    cancer: 'Cancer',
    personal_accident: 'Personal accident',
  },
  carQuoteBuyPage: {
    carQuoteReview: {
      ownerTitle: 'Owner details',
      vehicleTitle: 'Vehicle details',
    },
    carQuoteBuyForm: {
      vehicleTitle: 'Fill vehicle details',
      ownerTitle: 'Fill owner details',
    },
  },
  carInsurancePayment: {
    referredVehicleUsageNotSet: 'Sorry! you cannot purchase your car insurance from {{insurerName}} right now because its declaration of vehicle usage is not available. \nYou are able to purchase later when the declaration is available.',
  },
  thankYou: {
    title: 'Thank You!',
    description: 'Congratulations! You are now protected. Your policy details will be delivered to you shortly.',
    goToDashboard: 'Go to Dashboard',
  },
  thankYouBeta: {
    title: 'Thank you for trying our beta insurance',
    description: 'Congratulations! You are now added to our frontline\'s contact list. We will contact you soon.',
  },
  aboutUsPage: {
    heading: 'About MGA Cover',
    subHeading: 'Cambodia\'s First Full InsurTech Platform',
    vision: 'Vision',
    visionStatement: 'We strive to be one of the world’s most trusted InsurTech companies.',
    mission: 'Mission',
    missionStatement: 'The mission of MGA Cover is simple: Make insurance simple, transparent, and reliable for our customers so they can achieve their aspirations.',
    whoAreWe: 'Who are we?',
    whoAreWeDescription: 'MGA Cover was initiated by Mr. Meas Sophat, the Executive Chairman of MGA Asia Insurance Brokers Co., Ltd, in the early outbreak of Covid-19. Driven by his belief and passion about technology, he believes MGA Cover shall drive insurance in the kingdom of Cambodia forward and make a positive impact for the many Cambodian people.',
  },
  contactUsPage: {
    businessHours: 'Monday to Saturday, 8:00 AM - 5:30 PM, excluding public holidays',
    callUs: 'Call us',
    emailUs: 'E-mail us',
    headOffice: 'Our head office',
    generalInquiry: 'General inquiry',
  },
  paymentOption: {
    title: 'Choose way to pay',
    abaPayTitle: 'ABA PAY',
    abaPayDesc: 'Scan to pay with ABA Mobile app',
    khqrTitle: 'KHQR',
    khqrDesc: 'Scan to pay with member bank app',
  },
  userCarStatuses: {
    unverified: 'Unverified',
    reviewing: 'Reviewing',
    rejected: 'Rejected',
    verified: 'Verified',
  },
  fuelTypes: {
    unknown: 'Unknown',
    petrol: 'Petrol',
    diesel: 'Diesel',
    hybrid: 'Hybrid',
  },
  personalInfo: {
    changeabilityWarningMessage: 'It\'s important that you must input your personal information correctly the same as in your ID card or passport because once it\'s saved you cannot change. Please fill in carefully before moving to next step.',
    supportContact: 'For more information please contact',
  },
  genders: {
    male: 'Male',
    female: 'Female',
  },
  userDocumentStatuses: {
    unverified: 'Unverified',
    reviewing: 'Reviewing',
    rejected: 'Rejected',
    verified: 'Verified',
  },
  userDocument: {
    new: 'New Document',
  },
  toast: {
    userDocument: {
      create: {
        success: 'Document was successfully created',
      },
      update: {
        success: 'Document was successfully updated',
      },
    },
    signedIn: 'You have signed in successfully',
    signedOut: 'You have signed out successfully',
    personalInfo: {
      updateSuccess: 'Personal info was successfully updated',
    },
  },
  userDocumentTypes: {
    idCard: 'ID card',
    passport: 'Passport',
    drivingLicense: 'Driving license',
  },
  orderStatuses: {
    pending: 'Pending',
    expired: 'Expired',
    active: 'Active',
    ended: 'Ended',
    locked: 'Locked',
  },
  superSeo: {
    home: {
      title: 'MGA Cover: Buy Insurance Policies Online. Instant Cover.',
      description: 'Car insurance made simple, transparent, and reliable. Find best car insurance policies online. Get an instant cover.',
    },
    aboutUsPage: {
      title: 'About MGA Cover',
      description: 'Cambodia\'s First Full InsurTech Platform. The mission of MGA Cover is simple: Make insurance simple, transparent, and reliable for our customers so they can achieve their aspirations.',
    },
    contactUsPage: {
      title: 'Contact us - MGA Cover',
      description: 'Whether you have inquiries about online instant car insurance quotes, covers, claims, or any support, you can reach out to us through MGA Cover contact details.',
    },
    carInsurancePage: {
      title: 'MGA Cover: Buy Car Insurance Policies Online in Cambodia. Instant Cover.',
      description: 'Get free instant car insurance quotes online. Compare and buy/renew car insurance policy online in Cambodia. Affordable premiums from top car insurance companies in Cambodia.',
    },
    healthInsurancePage: {
      title: 'Buy Health Insurance Plans Online in Cambodia - MGA Cover',
      description: 'Get instant health insurance quotes online with affordable premiums. Compare and buy/renew health insurance policy online in Cambodia.',
    },
    personalAccidentInsurancePage: {
      title: 'Buy Personal Accident Insurance Policy Online in Cambodia - MGA Cover',
      description: 'Compare and buy/renew personal accident insurance policy online. Get cover against accidental death, permanent disability, and medical expenses.',
    },
    cancerInsurancePage: {
      title: 'Buy Cancer Insurance Policy Online in Cambodia - MGA Cover',
      description: 'Compare and buy/renew cancer insurance policy online. Get cover against cancer treatment.',
    },
  },
  notFoundPage: {
    title: 'Page not found',
    desc: 'The page you\'re looking for doesn\'t exist',
  },
  photoManagerDialog: {
    title: 'Photo Manager',
    tabs: {
      photos: {
        title: 'Photos',
      },
      upload: {
        title: 'Upload',
        usageMsg: 'Drag and drop files here or click',
        maxSizeMsg: 'The maximum accepted file size is: 5MB',
        maxFilesMsg: 'The maximum number of files you can upload is: {{maxFiles}}',
        reachLimitMsg: 'You have reached your photo limit!',
      },
    },
  },
  carInsurancePage: {
    headerSection: {
      title: 'Car insurance',
      item1: 'Get free instant car insurance quotes',
      item2: 'Affordable premium from top car insurance companies',
      item3: 'Find out ways to save money on car insurance',
      item4: 'Compare car insurance rates for free',
    },
    definitionSection: {
      title: 'What is Car Insurance?',
      description: 'Car insurance, also known as auto insurance or vehicle insurance, provides financial protection to you and your car against any unexpected losses or damages caused from accidents, fire, theft, as well as damage to any third-party person or property. Instead of paying for costly injuries or damaged property by yourself, your car insurance comes in to pay for some or all of the loss or damage.',
    },
    coverSection: {
      title: 'What Does Car Insurance Cover?',
      subTitle: 'Here are the common inclusions under Car Insurance Policy. Please refer to the policy wordings for more information about the policy coverage.',
      item1: {
        label: 'Accidental Damages',
        desc: 'Losses and damages due to accidents and collisions are covered.',
      },
      item2: {
        label: 'Fire',
        desc: 'Losses and damages caused to your car due to an accidental fire, self-ignition, vandalism etc',
      },
      item3: {
        label: 'Theft',
        desc: 'Covers for loss of your own whole car when your car is stolen',
      },
      item4: {
        label: 'Third-Party Liabilities',
        desc: 'If your car causes death or bodily injury to any person or damage to their car or property, such losses and damages and legal services are covered.',
      },
    },
    noCoverSection: {
      title: 'What Doesn\'t Car Insurance Cover?',
      subTitle: 'Here are the exclusions under Car Insurance Policy. Please refer to the policy wordings for a comprehensive list of exclusions.',
      item1: {
        label: 'General Wear & Tear',
        desc: 'The repair or spare parts replacement cost incurred as a result of regular use is not covered.',
      },
      item2: {
        label: 'Drunk Driving',
        desc: 'If your authorized driver or you were found driving under the influence of alcohol or drugs, any losses or damages are not covered.',
      },
      item3: {
        label: 'Driving Without a License',
        desc: 'It does not cover for any damages or losses incurred while driving without a valid driving license.',
      },
      item4: {
        label: 'Pre-Existing Damages',
        desc: 'Any damage that your car has suffered before buying a policy will not be covered.',
      },
      item5: {
        label: 'Commercial Use of Your Car',
        desc: 'Car insurance policy only covers a kind of private car usage so that it does not cover for any losses or damages caused from commercial purposes.',
      },
      item6: {
        label: 'Contributory Negligence',
        desc: 'Any losses or damages caused due to your own negligence against recommendation as per the manufacturer\'s driving manual will not be covered. For instance, you drive your car in a flood.',
      },
      item7: {
        label: 'Consequential Damages',
        desc: 'Any loss and damages that are not a direct result of the accident will not be covered. For instance, if the damaged car is not driven properly after the accident and its engine gets damaged, it will not be covered.',
      },
      item8: {
        label: 'Mechanical And Electrical Damages',
        desc: 'Any damage which is not a direct result of the accident (e.g. after an accident, if the damaged car is driven incorrectly and the engine gets damaged, it will not be covered)',
      },
      item9: {
        label: 'Geographical Limits',
        desc: 'Any loss or damage to your car when using outside the Geographical Area of Cambodia will not be covered.',
      },
    },
    addOnCoverSection: {
      title: 'Add-on Covers in Car Insurance Policy',
      subTitle: 'Add-on covers are extended covers or benefits to basic covers of your car insurance policy. However, they increase the premium accordingly, so you need to check the details while adding. There are two available add-on covers you can select from MGA Cover.',
      item1: {
        label: 'Accident to Driver',
        desc: 'This cover provides indemnity for the death or permanent disablement or bodily injury to any authorized person driving the car at the time of the occurrence of the event out of which any claim arises. Authorized Driver refers to any person who drives your car on your order or permission but only if the person has a valid driving license in Cambodia and is not under supervision or disqualification from driving.',
      },
      item2: {
        label: 'Passenger Liability',
        desc: 'This cover provides indemnity for the death of or bodily injury to any person being carried in or entering or getting on to or getting off the motor vehicle at the time of the occurrence of the event out of which any claim arises.',
      },
    },
    whyNeedSection: {
      title: 'Why Do You Need Car Insurance?',
      subTitle: 'Although it is not yet compulsory in the kingdom of Cambodia, you should at least have a third-party car insurance policy when you own a private car. A car insurance policy provides you with financial liabilities that may arise towards a third-party or loss or own-damages to your car due to a road accident or any other unfortunate event. Here are some key benefits of car insurance:',
      item1: {
        label: 'Cover Against Car Damage',
        desc: 'If your car is damaged due to an accident, a collision or fire, your Comprehensive/Standard car insurance policy can provide financial protection.',
      },
      item2: {
        label: 'Cover Against Theft',
        desc: 'A Comprehensive/Standard Car Insurance Policy provides financial protection against loss of your own whole car when your car is stolen. The compensation would be in accordance with the Insured Declared Value (IDV) stated in your policy, subject to terms and conditions.',
      },
      item3: {
        label: 'Cover Against Authorized Driver Accident',
        desc: 'If you want to protect yourself as your own-driver or your authorized drivers from permanent total disability and death caused by car accidents, Accident to Driver add-on cover is an extended financial shield against such loss and damage.',
      },
      item4: {
        label: 'Receive Cashless Services',
        desc: 'Car insurance usually includes cashless repairs provided by the certified network garages that car insurance companies have partnered with. Be sure that you check the network of garages of the car insurance company you’re going to buy from whether they are available in the area of your residence.',
      },
      item5: {
        label: 'Shield Against Third-Party Losses',
        desc: 'Being liable to a death or bodily injury to any person or damage to their car or property caused by your car is a severe financial burden. However, your third-party car insurance policy plays an important role against these third-party liabilities.',
      },
      item6: {
        label: 'Give Yourself A Peace of Mind',
        desc: 'Holding a car insurance policy can make you relieved from mental stress as you are financially protected for unfortunate events and you can drive with confidence.',
      },
    },
    keyFeatureSection: {
      title: 'Car Insurance Key Features',
      item1: {
        label: 'Own Damage/Theft Cover',
        desc: 'This coverage is available in a Comprehensive/Standard car insurance plan.',
      },
      item2: {
        label: 'Third-Party Damages Cover',
        desc: 'This covers against death or bodily injury to any person or damage to their car or property caused by your car.',
      },
      item3: {
        label: 'Add-on Covers',
        desc: 'Extra benefits to basic car insurance coverage. There are two available add-on covers: Accident to Driver & Passenger Liability.',
      },
      item4: {
        label: 'Car Insurance Premium',
        desc: 'MGA Cover car insurance offers incredibly affordable prices.',
      },
      item5: {
        label: 'Discounts',
        desc: 'Basic, loyal, and promotional discounts',
      },
      item6: {
        label: 'No Claim Bonus',
        desc: 'Up to 50% Discount',
      },
      item7: {
        label: 'Cashless Services',
        desc: 'Available through network garages of each car insurance company.',
      },
      item8: {
        label: 'Buying Process',
        desc: '100% digital-driven',
      },
      item9: {
        label: 'Claim Process',
        desc: 'Seamless claim process via online. Cashless, quick, and easy claim settlements.',
      },
    },
    buyConsiderationSection: {
      title: 'What to Consider When Buying Car Insurance Policy',
      item1: {
        label: 'Scope of Coverage',
        desc: 'Although Third-Party car insurance is not mandatory in Cambodia yet, every car owner should consider this coverage as it provides huge financial benefits and incredibly low premium. If you want to extend to greater coverage, Comprehensive/Standard car insurance is recommended; however, it is very important that you have to check all inclusions and exclusions of the plan before buying it.',
      },
      item2: {
        label: 'Add-on Covers',
        desc: 'It is also wise to consider add-on covers with your car insurance plan in order to enhance the policy’s coverage and maximize the policy’s financial benefits. Apparently, it depends on your requirement and budget so choose only the ones you need.',
      },
      item3: {
        label: 'Right Insured Declared Value (IDV)',
        desc: 'Insured Declared Value (IDV) is the maximum or highest amount you will receive from the insurer in case of full damage or theft of your car. Basically, it is an approximate current market value of your car. Thus, it is crucial to check it precisely before making a purchase decision. A higher IDV offers greater insurance protection, but it increases the premium.',
      },
      item4: {
        label: 'Compare Car Insurance Quotes',
        desc: 'You can leverage car insurance quotes comparison feature to find the best car insurance rate and policy as per your requirements. ',
      },
      item5: {
        label: 'Network Garages',
        desc: 'Checking car insurance companies’ network garages allows you to access cashless repairs in the place you live. Ideally, you save your money. ',
      },
      item6: {
        label: 'Claim Process',
        desc: 'The main reason to have car insurance is to get compensation in return. This is a so-called insurance claim. Hence, it’s crucial to check the claim process of your desired car insurance company whether it is smooth and convenient.',
      },
      item7: {
        label: 'Claim Settlements',
        desc: 'A satisfying amount of compensation is what you usually expect from your car insurance. It’s very important that you check out the car insurance company’s claim settlement record so that you can rest assured that your claims will eventually be settled.',
      },
      item8: {
        label: 'Customer Support',
        desc: 'Certainly, you always want responsive customer service in all circumstances—either you are in trouble of a road accident or you need assistance in filing a claim. So, look for a car insurance company that gives you 24x7 support, and you always have someone to rely on!',
      },
    },
    whichPlanSection: {
      title: 'Which Car Insurance Plan Is Right For You?',
      item1: {
        label: 'Third-Party Liability',
        desc: 'Third-party car insurance is the most common type of car insurance plan in which only losses and damages caused to a third-party person, their car or property are covered.',
      },
      item2: {
        label: 'Comprehensive / Standard',
        desc: 'Comprehensive/Standard car insurance is the most valuable type of car insurance that covers against third-party liabilities, and losses and damages to your own car due to accidents, fire, and theft.',
      },
    },
    thirdPartyAndComprehensiveSection: {
      title: 'Difference Between Third-Party and Comprehensive/Standard Plans',
      item1: {
        label: 'Damages/Losses to own car due to an accident',
        desc: 'Covers for damages to your own car in case of an accident or collision.',
        thirdParty: 'no',
        comprehensive: 'yes',
      },
      item2: {
        label: 'Losses/Damages to own car in case of fire',
        desc: 'Covers against losses and damages to your own car caused due to an accidental fire.',
        thirdParty: 'no',
        comprehensive: 'yes',
      },
      item3: {
        label: 'Theft',
        desc: 'Covers for losses if your car is unfortunately stolen.',
        thirdParty: 'no',
        comprehensive: 'yes',
      },
      item4: {
        label: 'Damages to Third-Party Vehicle',
        desc: 'Covers against damages caused by your car to any third-party vehicle',
        thirdParty: 'yes',
        comprehensive: 'yes',
      },
      item5: {
        label: 'Damages to Third-Party Property',
        desc: 'Covers for losses and damages caused by your car to any third-party property',
        thirdParty: 'yes',
        comprehensive: 'yes',
      },
      item6: {
        label: 'Bodily Injuries/Death of a Third-Party Person',
        desc: 'Covers for bodily injuries or death caused by your car to any third-party person',
        thirdParty: 'yes',
        comprehensive: 'yes',
      },
      item7: {
        label: 'Customize IDV',
        desc: 'Customizing the IDV of your car affects your car insurance premiums.',
        thirdParty: 'no',
        comprehensive: 'yes',
      },
      item8: {
        label: 'Add-on Covers',
        desc: 'Give your car extra benefits with customized add-ons such as Accident to Driver and Passenger Liability.',
        thirdParty: 'yes',
        comprehensive: 'yes',
      },
    },
    buyReasonSection: {
      title: '8 Reasons to Buy Car Insurance on MGA Cover',
      item1: {
        label: '1. Instant Quote',
        desc: 'View instant quotes online from top insurers in Cambodia in just less than 2 minutes.',
      },
      item2: {
        label: '2. Affordable Premium',
        desc: 'Purchasing insurance should not be a financial burden for you. We offer the right insurance plan for your budget.',
      },
      item3: {
        label: '3. Transparency',
        desc: 'Our mission is to make car insurance more transparent to you so that you get as much clear information as possible before you decide upon your car insurance policy.',
      },
      item4: {
        label: '4. Compare Quotes Online',
        desc: 'You can leverage MGA Cover’s car insurance quotes comparison feature to find the best car insurance rate and policy as per your requirements. This is great for the value of your money.',
      },
      item5: {
        label: '5. Convenient Buying Process',
        desc: 'Now, you can have an online experience with us in buying your car insurance rather than the old school offline method. It is quick and convenient in just less than 5 minutes, so it saves you a lot of time and effort.',
      },
      item6: {
        label: '6. Instant Cover',
        desc: 'No awaiting confirmation of protection. You are instantly covered after a successful purchase.',
      },
      item7: {
        label: '7. Seamless Claim Proces',
        desc: 'Our goal is to make insurance claims easier. All you have to do is to submit your claim online and experience cashless, quick, and easy claim settlements. We will be with you every step of the way.',
      },
      item8: {
        label: '8. Customer Support',
        desc: 'Our customer service team always stands by you assisting you as per your needs before or after purchase via emails, chats, and calls.',
      },
    },
    howToCompareSection: {
      title: 'How to Compare Car Insurance Quotes Online?',
      subTitle: 'MGA Cover provides a car insurance quote comparison function to help you compare car insurance quotes online from top car insurance companies in Cambodia. Here are 4 steps to compare car insurance quotes:',
      item1: {
        label: 'Get Quote with MGA Cover',
        desc: 'Initially, get started with instant quotes by answering a few questions about your car such as make, model, variant, and year of manufacture.',
      },
      item2: {
        label: 'Select Quotes',
        desc: 'Once you get your quote list, just click the Compare button in the quote card in order to select quotes that you want to compare. You can choose up to 3 quotes for comparison.',
      },
      item3: {
        label: 'Start Comparing',
        desc: 'After selecting your desired quotes, click to open the comparison tab at the bottom of the page and click the Compare button to begin comparison.',
      },
      item4: {
        label: 'Choose The Right Car Insurance Plan',
        desc: 'Here you are able to compare car insurance quotes based on premium, types, coverage, add-ons, deductible, exclusion, and more, via which you can buy the best car insurance plan that suits you with the right budget online through secure payment methods.',
      },
    },
    howToBuySection: {
      title: 'How to Buy Car Insurance Online?',
      subTitle: 'MGA Cover simplifies the car insurance buying process for you. You can buy your car insurance policy online by following the easy steps mentioned below:',
      item1: 'Answer a few simple questions about your car like car brand, model, engine number and year of manufacture to get car insurance quotes.',
      item2: 'View various quotes from different car insurance companies and compare them with detailed information.',
      item3: 'Select Add-on Covers to add extra protection as per your needs.',
      item4: 'Choose your affordable and right car insurance plan.',
      item5: 'You’re required to create an account in order to buy a car insurance policy',
      item6: 'Fill in your personal details',
      item7: 'Pay Online securely. Once the payment is made successfully, you will get an email notification confirming that you are being protected.',
      item8: 'You can login to the dashboard to view payment transactions, car insurance policy details, or download documents in PDF.',
    },
    howToFileClaimSection: {
      title: 'How to File a Car Insurance Claim?',
      subTitle: 'You can submit a claim online inside MGA Cover user dashboard by the following easy steps:',
      item1: 'Sign in your account',
      item2: 'In your dashboard, view all your policies and click the “Claim” button on the policy that you want to register a claim',
      item3: 'Fill in required details, upload all claim-related documents, and then submit',
      item4: 'You can track your claim status in the Claim menu. Your claim will be settled if it is approved.',
    },
    documentClaimSection: {
      title: 'Documents Required for Filing a Car Insurance Claim Online',
      subTitle: 'You are required to upload some or all of the following documents on MGA Cover platform while submitting your car insurance claim:',
      item1: 'Copy of Driving License',
      item2: 'Copy of Registration Card',
      item3: 'Copy of Quotation',
      item4: 'Copy of Repair Payment Receipts',
      item5: 'Accident Photos',
      item6: 'Police Report',
    },
  },
  healthInsurancePage: {
    headerSection: {
      title: 'Health insurance',
      subTitle: 'Health insurance is an essential financial tool that covers against the costs of medical treatment for injuries and illnesses.',
      item1: 'Get free instant health insurance quotes',
      item2: 'Affordable premium from top health insurance companies',
      item3: 'Find out ways to save money on health insurance',
      item4: 'Compare health insurance rates for free',
    },
    definitionSection: {
      title: 'What is Health Insurance?',
      description: 'Health insurance is a type of insurance that covers the medical expenses of an individual or a group of people in case of illness, injury, or disability. Health insurance can help you pay for various health care services, such as hospitalization, surgery, doctor visits, prescription drugs, preventive care, and more.',
    },
    benefitSection: {
      title: 'Benefits of Health Insurance Policy',
      subTitle: 'Holding a health insurance policy is one of the most important decisions you can make for yourself and your loved ones. Health insurance can provide you with many benefits, such as:',
      item1: {
        label: 'Financial Protection against unexpected and High Medical Costs',
        desc: 'Medical costs have become very expensive nowaday and illnesses have also become common due to the current lifestyle. Thus, if a medical emergency occurs, the expensive costs can drain your financial savings. A health insurance policy acts as a plug to stop the drain. It pays for the medical expenses and protects your savings.',
      },
      item2: {
        label: 'Access to quality healthcare',
        desc: 'Health insurance enables you to access a network of quality medical health care providers and hospitals, regardless of your income. This is because most health insurance plans cover a wide range of medical services, including preventive care, doctor\'s visits, hospital stays, and prescription drugs.',
      },
      item3: {
        label: 'Better health outcomes',
        desc: 'Taking good care of your health is crucial, and health insurance plays a role in that too. When you have coverage, you\'re more likely to seek preventive care, such as annual check-ups and screenings. As a result, any health issues can be early detected and timely intervened. Eventually it improves your long-term health outcomes. This is all about a proactive approach to stay on top of your well-being.',
      },
      item4: {
        label: 'Peace of mind',
        desc: 'Having health insurance can give you peace of mind knowing that you are financially protected in the event of a medical emergency. This can allow you to focus on your health and recovery, without having to worry about the cost of care.',
      },
    },
    coverSection: {
      title: 'What is Covered in a Health Insurance Plan?',
      subTitle: 'A health insurance plan can cover a wide range of medical expenses, depending on the type and scope of the plan. Some of the common expenses that are covered in a health insurance plan are:',
      item1: {
        label: 'Inpatient hospitalization',
        desc: 'The cost of staying in a hospital for more than 24 hours due to an illness or injury',
      },
      item2: {
        label: 'Pre-hospitalisation expenses',
        desc: 'The costs incurred before being hospitalized are covered for up to a specific duration as per the policy’s terms and conditions.',
      },
      item3: {
        label: 'Post-hospitalization expenses',
        desc: 'The costs incurred after being discharged from the hospital are covered for up to a specific duration that is specified in the policy terms and conditions. These costs are incurred for monitoring your recovery after treatments.',
      },
      item4: {
        label: 'Day care treatments',
        desc: 'Minor treatments like cataract surgery, tonsil removal, dialysis, or similar procedures that require less than 24 hours of hospitalization are covered under health insurance plans.',
      },
      item5: {
        label: 'Domiciliary hospitalization',
        desc: 'The cost of treatments that are done at home under medical supervision due to lack of hospital beds or patient’s condition',
      },
      item6: {
        label: 'Ambulance charges',
        desc: 'The cost of transportation to and from the hospital in an emergency situation',
      },
      item7: {
        label: 'Maternity and newborn expenses',
        desc: 'The costs of delivery, prenatal and postnatal care, vaccination, etc. for the mother and the baby are covered under maternity benefit and coverage. This is beneficial when you are planning a family.',
      },
      item8: {
        label: 'Preventive health check-ups',
        desc: 'Health insurance plans cover the costs of regular health screenings and tests to detect any potential health issues depending on the policy’s terms and conditions.',
      },
      notice: 'Notice: The inclusions of health insurance plans are not the same for every policy. Please refer to your health insurance provider to check your policy wordings to get a detailed list of cover.',
    },
    noCoverSection: {
      title: 'What is Not Covered in a Health Insurance Plan?',
      subTitle: 'A health insurance plan may not cover some medical expenses that are excluded from the policy. Some of the common exclusions in a health insurance plan are:',
      item1: {
        label: 'Pre-existing diseases',
        desc: 'Any disease or condition that the insured person has had before buying the policy or during the waiting period',
      },
      item2: {
        label: 'Cosmetic surgery',
        desc: 'Any surgery or procedure that is done to enhance the appearance or beauty',
      },
      item3: {
        label: 'Dental treatment',
        desc: 'Any treatment or procedure that is related to the teeth or gums',
      },
      item4: {
        label: 'Self-inflicted injuries',
        desc: 'Any injury or harm that is caused by the insured person’s intentional or deliberate act, such as suicide or drug abuse',
      },
      item5: {
        label: 'War and terrorism',
        desc: ' Any injury or damage that is caused by war, civil unrest, riots, strikes, terrorist activities, etc.',
      },
      item6: {
        label: 'Experimental treatments',
        desc: 'Any treatment or procedure that is not scientifically proven or approved by medical authorities',
      },
      notice: 'Notice: The exclusions of health insurance plans are not the same for every policy. Please refer to your health insurance provider to check your policy wordings to get a detailed list of exclusion.\n',
    },
    eligibilitySection: {
      title: 'Who is Eligible to Buy a Health Insurance Policy?',
      subTitle: 'To buy a health insurance policy, you need to meet certain eligibility requirements set by the insurer. The eligibility requirements may vary depending on the type of health insurance plan and the insurer. However, here are some of the common eligibility requirements:',
      item1: {
        label: 'Age',
        desc: 'You need to be within a certain age range to buy a health insurance policy. For example, most individual health insurance plans have a minimum entry age of 18 years and a maximum entry age of 65 years. For family plans, the minimum entry age may be lower for children and higher for senior citizens.',
      },
      item2: {
        label: 'Health status',
        desc: 'You need to disclose your current and past medical history to the insurer. You may also need to undergo a medical examination or submit medical reports to prove your health status. The insurer may reject your application or charge you a higher premium if you have any pre-existing diseases or high-risk factors, such as smoking, obesity, etc.',
      },
      item3: {
        label: 'Occupation',
        desc: 'You need to provide your occupation. The insurer may offer you different plans and premiums based on your occupation. For example, some plans may be designed for professionals, such as doctors, lawyers, engineers, etc.',
      },
    },
    buyEarlySection: {
      title: 'Importance of Buying a Health Insurance Plan at an Early Age',
      subTitle: 'Buying a health insurance plan at an early age is a wise and beneficial decision. Buying a health insurance plan at an early age can offer you many advantages, such as:',
      item1: {
        label: 'Lower premium',
        desc: 'The premium of a health insurance plan depends on your age and health status. The younger and healthier you are, the lower the premium you have to pay. As you grow older, the premium increases due to the higher risk of illness and injury.',
      },
      item2: {
        label: 'Longer coverage',
        desc: 'The longer you stay insured, the more benefits you can enjoy. You can get coverage for a longer period and avoid any gaps or lapses in your policy. You can also renew your policy with great loyalty bonuses and without any hassle or restrictions. ',
      },
      item3: {
        label: 'Wider options',
        desc: 'The earlier you buy a health insurance plan, the more options you have to choose from. You can select the plan that best suits your needs and preferences. You can also switch or upgrade your plan as per your changing requirements and expectations.',
      },
    },
    frequentlyAskedQuestionSection: {
      title: 'Health Insurance Frequently Asked Questions',
      q1: 'Why should I buy health insurance?',
      a1: 'Health insurance is an essential shield because it provides financial protection against expensive medical costs and healthcare services. You can access timely and quality medical care without worrying about the heavy burden of expenses. It offers peace of mind, safeguards against unexpected medical emergencies, and promotes overall well-being by encouraging regular health check-ups, screenings, and preventive care.',
      q2: 'What is the right age to buy health insurance?',
      a2: 'There is no right age to buy health insurance, but a reasonable eligibility age for adults is between 18 to 65 years old while eligibility age for children lies between 90 days to up to 18 years. It is recommended that the earlier you purchase health insurance the lower premium you get as you are young and healthier and less prone to critical illnesses and hospitalization as compared to someone in their 50s and 60s.',
      q3: 'What does cashless hospitalization mean in a health insurance policy?',
      a3: 'Cashless hospitalization in a health insurance policy means that you can get hospitalized and receive treatment without having to pay any money upfront. The insurance company will pay the hospital directly on your behalf as per the policy terms and conditions. All insurance companies have partnership with a large network of hospitals where the insured person can avail the cashless treatment. You just need to provide your insurance information to the hospital.',
      q4: 'What is hospital accommodation in health insurance?',
      a4: 'Hospital accommodation in health insurance is the coverage of the costs associated with staying in a hospital, such as the room, meals, and other amenities. Most health insurance plans will cover some or all of the costs of hospital accommodation, but the specific coverage will vary depending on the plan.',
      q5: 'What is a health insurance plan copay?',
      a5: 'A health insurance plan copay is a fixed amount of money that you have to pay for certain medical services such as a doctor\'s visit or prescription drug. For example, you may have a $20 copay for each doctor\'s visit.',
      q6: 'What is a pre-existing condition in health insurance?',
      a6: 'A pre-existing condition in health insurance is any disease, injury, or medical condition that you had before applying for a new health insurance plan. This can include chronic conditions, such as asthma, diabetes, or heart disease, as well as acute conditions, such as a broken bone or a recent surgery.',
      q7: 'What will happen if my policy lapses when I am hospitalized?',
      a7: 'If your health insurance policy lapses while you are hospitalized, you will be responsible for paying all of your medical expenses out of pocket. This can be a significant financial burden, especially if you have a long or complicated hospitalization.\n\nSome insurance companies may offer a grace period after your policy lapses. This means that you may have a certain amount of time to renew your policy before your coverage is terminated. If you renew your policy during the grace period, you will still be covered for your hospitalization.\n\nHowever, if you do not renew your policy during the grace period, your coverage will be terminated and you will be responsible for paying all of your medical expenses out of pocket.',
    },
    disclaimer: 'Disclaimer: This landing page is for health insurance education only. It does not reflect any insurance policies.',
  },
  healthQuoteForm: {
    members: {
      title: 'Choose any person that you wish to insure',
      gender: 'Your gender',
    },
    birthdates: {
      title: 'Fill in the date of birth',
    },
    policyEffectiveDate: {
      title: 'Set the effective date',
    },
  },
  healthQuote: {
    contact: {
      title: 'Tell us about your self',
    },
    authorizedNote: 'I hereby authorize MGA Cover to communicate with me on the given number for my Insurance needs.',
    totalPlans: '{{count}} health insurance plans',
    annualLimit: 'Annual limit',
    coveredMembers: 'Members covered',
    geographicCoverage: 'Geographic coverage',
    hospitalAccommodation: 'Hospital accommodation',
    copay: 'Copay',
    waitingPeriod: 'Waiting period',
    cashlessHospitals: 'Cashless hospitals',
    premium: 'Premium',
    planDetails: 'Plan Details',
    viewMore: '{{count}} more plans',
    myAge: 'Myself ({{age}} years old)',
    medicalQuestions: {
      error: {
        title: 'Unqualified members found!',
        description: 'Members listed below are not fulfilled with all the requirements demanded by the insurance company.\nTo proceed further, you have to make sure that you and any members don\'t have any health problems mentioned above or you remove unqualified members from the quote so that you will get a revised quote.',
      },
    },
    medicalCondition: 'Help us know the medical condition, if any We\'ll only ask for the details insurance companies need',
    filters: 'Filter',
    ipd: 'Inpatient',
    opd: 'Outpatient',
    maternity: 'Maternity',
    healthCheckup: 'Health Checkup',
    vision: 'Vision',
    dental: 'Dental',
  },
  healthPlanBenefitTypes: {
    inpatient: 'Inpatient Benefits',
    outpatient: 'Outpatient Benefits',
    healthCheckup: 'Health Checkup Benefits',
    dental: 'Dental Benefits',
    maternity: 'Maternity Benefits',
    vision: 'Vision Benefits',
    copay: 'Copay Benefits',
    others: 'Other Benefits',
    ipdHospitalRoomAndBoard: 'Hospital Room and Board',
    ipdIcuAndSimilar: 'ICU and similar',
    ipdHospitalGeneralExpenses: 'Hospital General Expenses',
    ipdSurgicalBenefits: 'Surgical benefits',
    ipdPsychiatricCare: 'Psychiatric Care',
    ipdCashAllowance: 'Cash Allowance',
    ipdEmergencyTransportationFee: 'Emergency Transportation Fee',
    opdBenefits: 'OPD Benefits',
    opdPrescribed: 'Prescribed',
    otherPreExistingCondition: 'Pre-existing condition',
    otherChronicCondition: 'Chronic condition',
    otherAidHiv: 'AID/HIV',
    otherCongenitalCondition: 'Congenital condition',
    otherCovid19: 'Covid 19',
  },
  healthQuoteDetailsPage: {
    coverAmount: 'Cover amount',
    policyWording: 'Policy wording',
    planBrochure: 'Plan brochure',
    membersCovered: {
      title: 'Members covered',
      age: '{{age}} {{pluralizedYear}} old',
      edit: 'Edit members',
    },
  },
  healthQuoteBuyPage: {
    title: 'Buy policy',
    subTitle: 'Please fill in insured persons details which you wish to include in the same policy and next answer the questionnaire.',
  },
  healthQuoteReviewPage: {
    memberDetailsTitle: 'Member details',
  },
  ordinalItem_one: '{{count}}st {{item}}',
  ordinalItem_two: '{{count}}nd {{item}}',
  ordinalItem_few: '{{count}}rd {{item}}',
  ordinalItem_other: '{{count}}th {{item}}',
  personalAccidentInsurancePage: {
    headerSection: {
      title: 'Personal Accident Insurance',
      subTitle: 'Accidents can be harmful to a person and loved ones.',
      item1: 'Get free instant personal accident insurance quotes',
      item2: 'Affordable premium from top personal accident insurance companies',
      item3: 'Find out ways to save money on personal accident insurance',
      item4: 'Compare personal accident insurance rates for free',
    },
    definitionSection: {
      title: 'What is Personal Accident Insurance?',
      description: 'Personal accident insurance is a type of insurance policy that provides financial protection in the event of an accidental death, partial or total disability, permanent as well as temporary disability, or medical expenses. It ensures you and your loved ones will be financially secure in the event of an unexpected accident.\n\nAn accident can occur anywhere at any time and turn your life upside down – not only will you be affected physically and emotionally, it can also end up being a financial burden. If you fortunately have a regular health insurance policy, it might just cover standard medical expenses like inpatient hospitalization expenses.\n\nFor example, if you fall down the stairs and get broken bones or head injuries, you might end up facing a lot of treatment costs. With a Personal Accident Insurance Cover, you will be able to get a specific lump sum amount to cover medical and related expenses, as well as any income loss while you are recovering from this injury so that you can be sure of your financial stability. ',
    },
    whyNeedSection: {
      title: 'Why Do You Need Personal Accidental Insurance Cover?',
      item1: {
        label: 'Financial protection in case of accidental death',
        desc: 'If you die as a result of an accident, your beneficiaries will receive a lump sum payment to help them cope with your loss. This can help them pay for funeral expenses, outstanding debts, and living expenses.\n',
      },
      item2: {
        label: 'Financial protection in case of accidental disability',
        desc: 'If you suffer a permanent total or partial disability as a result of an accident, you will receive a lump sum payment to help you cover your lost income and medical expenses. The amount of the benefit will typically depend on the severity of your disability.\n',
      },
      item3: {
        label: 'Reimbursement of medical expenses',
        desc: 'If you are injured in an accident, your medical expenses will be reimbursed by the insurance company. This can include the cost of hospitalization, surgery, and other medical treatments.',
      },
      item4: {
        label: 'Peace of mind',
        desc: ' Knowing that you have personal accident insurance can give you peace of mind. You can rest assured that you and your loved ones will be financially protected in the event of an unexpected accident.',
      },
    },
    coverSection: {
      title: 'What is Covered in Personal Accident Insurance?',
      item1: {
        label: 'Accidental death',
        desc: 'If you die as a result of an accident, your family will receive a lump sum amount. It acts as financial support when they need it the most. The amount of the death benefit varies depending on the policy you choose.',
      },
      item2: {
        label: 'Permanent total disability',
        desc: 'When an accident causes permanent total disability, a lump sum payment is paid to you. A permanent total disability means that you are unable to work or perform any of the essential duties of your occupation.',
      },
      item3: {
        label: 'Permanent partial disability',
        desc: 'A lump sum payment is paid to you in the event of a permanent partial disability resulting from an accident. A permanent partial disability means that you are able to work but your earning capacity has been reduced.',
      },
      item4: {
        label: 'Temporary Disability',
        desc: ' If you are not able to work for some time because of temporary disability resulting from an accident, you can get a lump sum compensation to cover your daily expenses.',
      },
      item5: {
        label: 'Medical expenses',
        desc: 'It covers your medical bills due to the accident. From hospital stays to surgeries, it helps lighten your financial load.',
      },
    },
    noCoverSection: {
      title: 'What is Not Covered in Personal Accident Insurance?',
      item1: {
        label: 'Self-inflicted injuries',
        desc: 'Injuries caused by the insured person intentionally or deliberately harming themselves are not covered by personal accident insurance. This includes suicide, self-harm, and injuries sustained while attempting to harm another person.',
      },
      item2: {
        label: 'Pre-existing conditions',
        desc: 'Personal accident insurance generally does not cover injuries or illnesses that existed before the policy was purchased. If an accident worsens a pre-existing condition, the insurance may only cover the portion of the injury or illness that is directly attributable to the accident.\n',
      },
      item3: {
        label: 'Death from natural causes',
        desc: 'Death from natural causes, such as disease or old age, is not covered.',
      },
      item4: {
        label: 'Injuries sustained while participating in hazardous activities',
        desc: 'Personal accident insurance policies often exclude injuries sustained while engaging in high-risk or dangerous activities, such as skydiving, mountain climbing, scuba diving, or motor racing. These activities are considered to carry an increased risk of injury, and insurers may not cover accidents that occur while participating in them.',
      },
      item5: {
        label: 'Influence of alcohol or drugs',
        desc: 'If an accident occurs while the insured person is under the influence of alcohol or drugs, the insurance company may deny or reduce the claim. This is because intoxication can impair judgment and coordination, increasing the likelihood of accidents.',
      },
      item6: {
        label: 'Being involved in criminal activities',
        desc: 'Personal accident insurance does not cover injuries sustained while committing a crime or engaging in illegal activities. This is because insurers do not want to incentivize criminal behavior.',
      },
      item7: {
        label: 'War or riots',
        desc: 'Personal accident insurance policies typically exclude injuries sustained during war, civil unrest, or riots. These events are considered acts of violence beyond the scope of the policy\'s coverage.',
      },
      item8: {
        label: 'Driving without a license',
        desc: 'If an accident occurs while the policyholder is driving without a valid license, the insurance company may not provide coverage.',
      },
      item9: {
        label: 'Pregnancy or childbirth',
        desc: 'Some policies may exclude injuries or disabilities sustained during pregnancy or childbirth.',
      },
    },
    extraBenefitSection: {
      title: 'Extensions of Personal Accident Insurance Policy',
      item1: {
        label: 'Funeral expenses',
        desc: 'Some personal accident insurance policies reimburse the policyholder\'s funeral expenses as per specified amount in the policy. This can help alleviate the financial burden on the family during a difficult time.',
      },
      item2: {
        label: 'Ambulance expenses',
        desc: 'Some personal accident insurance policies provide a lump sum allowance for ambulance charges to carry you to the hospital due to an emergency situation.',
      },
      item3: {
        label: 'Education benefit',
        desc: 'Some personal accident insurance policies provide a lump sum payment to the policyholder\'s children to help cover the cost of their education in the event of the policyholder\'s accidental death.',
      },
      item4: {
        label: 'Disappearance',
        desc: 'Some personal accident insurance policies provide a lump sum allowance in the event of disappearance or kidnap.',
      },
      item5: {
        label: 'Food poisoning',
        desc: 'In case of food or drinking poisoning, your personal accident insurance policy can cover up to a fixed limit as per policy benefit schedule. ',
      },
      item6: {
        label: 'Snake, Insect, or Animal bites',
        desc: 'In any event of snake, insect, dog, or animal bites that require medical treatments or care, a lump sum payment is provided to cover such medical costs.',
      },
      item7: {
        label: 'Dengue fever, malaria, and/or chikungunya fever',
        desc: 'Some personal accident insurance policies cover for diseases like dengue fever, malaria, or chikungunya fever. The coverage is up to a specified limit amount as per policy benefit schedule.',
      },
    },
    eligibilitySection: {
      title: 'Eligibility criteria to buy a personal accident insurance policy',
      subTitle: 'The eligibility criteria to buy a personal accident insurance policy are simple. The buying process is quite straightforward for individuals and family members. The followings are the breakdown eligibility requirements:',
      item1: {
        label: 'Age',
        desc: 'You must be at least 18 years old and not more than 65 years old. Some insurers may have different age limits for certain types of occupations or activities.',
      },
      item2: {
        label: 'Residency',
        desc: 'You must be a resident of the country in which you are purchasing the policy.',
      },
      item3: {
        label: 'Health',
        desc: 'You must be in good health and free from any pre-existing medical conditions that could increase your risk of an accident. Some insurers may require you to undergo a medical examination before they will issue you a policy.',
      },
      item4: {
        label: 'Occupation',
        desc: 'You must not be engaged in an occupation that is considered to be high-risk or hazardous. Some insurers may require you to provide details of your occupation when applying for a policy.\n',
      },
      item5: {
        label: 'Income',
        desc: 'You are not required to file your income to obtain this insurance. It\'s available to people from all income levels.',
      },
      item6: {
        label: 'Criminal record',
        desc: 'You must not have a criminal record. Some insurers may ask you to provide a copy of your police record when you apply for a policy.',
      },
      item7: {
        label: 'Premium',
        desc: 'You need to pay the premium to keep your policy active. The premium amount depends on your chosen coverage and benefits.',
      },
    },
    whoShouldGetSection: {
      title: 'Who should get Personal Accident Insurance?',
      subTitle: 'Personal accident insurance is suitable for those who are eligible and feel a risk of an unexpected accident, regardless of their occupations and income levels. This might include:',
      tab1: {
        title: 'People With Low Risk Occupations',
        item1: 'Office workers (like consultants, accountants, and engineers)',
        item2: 'Health care workers',
        item3: 'Legal professionals',
        item4: 'Artists, writers, and designers',
        item5: 'Teachers and School Students',
        item6: 'Civil servants and Bureaucrats',
        item7: 'Bankers',
        item8: 'Shopkeepers',
        item9: 'Homemakers',
      },
      tab2: {
        title: 'People With High Risk Occupations',
        item1: 'Industrial Workers (non-hazard)',
        item2: 'Veterinarians',
        item3: 'Security officers',
        item4: 'Photographers and Chefs',
        item5: 'College / University Students',
        item6: 'Builders, contractors &  construction workers',
        item7: 'Hospitality and Tourism Sector Workers',
        item8: 'Airline Crew and Airport Staff',
        item9: 'Delivery Personnel',
      },
      tab3: {
        title: 'People With Very High Risk Occupations',
        item1: 'Industrial Workers  (hazard workers)',
        item2: 'Professional Athletes',
        item3: 'Police and Military armed personnel',
        item4: 'Mountaineers',
        item5: 'Journalists',
        item6: 'Politicians',
      },
    },
    frequentlyAskedQuestionSection: {
      title: 'Personal Accident Insurance FAQs',
      q1: 'What are the benefits of a personal accident policy?',
      a1: 'A personal accident policy will cover you in case of accidental death, permanent total or partial disability, temporary disability, medical expenses, and many other things like ambulance charges and funeral expenses. ',
      q2: 'Can I include my parents in this policy?',
      a2: 'Yes, you can add your parents, up to 65 years, as dependents under this policy.',
      q3: 'Does the personal accident insurance policy cover death?',
      a3: 'Yes, it does. In the case of an accidental death, your dependents will get the lump sum insured amount specified in the policy’s benefits schedule.',
      q4: 'But I also have a health insurance policy. Do I still need a personal accident insurance policy?',
      a4: 'A standard health insurance policy is actually different from a personal accident insurance policy. A health insurance policy will cover you in case of any illness and things like hospitalization costs are usually cashless or reimbursed to you. But, with a personal accident cover, you will get a lump sum amount to help you out at a time when financial support is needed.',
      q5: 'What documents are required for a personal accident insurance policy?',
      a5: 'Through MGA Cover’s Personal Accident Insurance, it’s an online process. You just fill in the required details and make the payment online and you’ll be on your way to being covered under your personal accident insurance plan.',
      q6: 'Do I need a separate personal accident insurance policy for my family members?',
      a6: 'No, actually you don’t! A personal accident insurance policy is a floater policy, which means that you can add your entire family including yourself, your spouse, dependent children, and parents under one plan.',
    },
    disclaimer: 'Disclaimer: This landing page is for personal accident insurance education only. It does not reflect any insurance policies.',
  },
  personalAccidentQuote: {
    featuredPlanBenefits: {
      death: 'Death due to accident',
      permanentAndTotalDisablement: 'Permanent and total disablement due to accident',
      permanentAndPartialDisablement: 'Permanent and partial disablement due to accident',
      medicalExpense: 'Medical expense due to accident',
    },
    annualLimit: 'Annual limit',
    geographicCoverage: 'Geographic coverage',
    cashlessHospitals: 'Cashless hospitals',
    myAge: 'Myself ({{age}} years old)',
    totalPlans: '{{count}} personal accident insurance plans',
  },
  personalAccidentQuoteBuyPage: {
    title: 'Buy policy',
    subTitle: 'Please fill in insured persons details which you wish to include in the same policy and next answer the questionnaire.',
  },
  personalAccidentQuoteReviewPage: {
    memberDetailsTitle: 'Member details',
  },
  personalAccidentPlanBenefitGroupTypes: {
    basicBenefits: 'Basic benefits',
    medicalExpense: 'Medical Expense',
    extensions: 'Extensions',
    iema: 'INTERNATIONAL EMERGENCY MEDICAL ASSISTANCE (IEMA)',
  },
  personalAccidentPlanBenefit: {
    basicBenefitsDeathDueToAccident: 'Death due to accident',
    basicBenefitsPermanentAndTotalDisablementDueToAccident: 'Permanent and total disablement due to accident',
    basicBenefitsPermanentAndPartialDisablementDueToAccident: 'Permanent and partial disablement due to accident',
    basicBenefitsMedicalExpenseDueToAccident: 'Medical expense amount due to accident',
    medicalExpenseInpatientAndOutpatientTreatmentDueToAccident: 'Inpatient and outpatient treatment due to accident',
    medicalExpenseLocalGroundAmbulance: 'Local ground ambulance',
    extensionsCashAllowance: 'Cash allowance',
    extensionsDisappearance: 'Disappearance',
    extensionsFuneralExpensesSubsidyNonAccidentalDeath: 'Funeral expenses Subsidy (non-accidental death)',
    extensionsNoClaimBonus: 'No claim bonus',
    extensionsSnakeBitesAndInsectBites: 'Snake Bites and Insect Bites Clause',
    extensionsAccidentalMiscarriage: 'Accidental miscarriage',
    extensionsFoodPoisoning: 'Food poisoning',
    extensionsFuneralBenefit: 'Funeral benefit',
    extensionsIncrementBonus: 'Incremental bonus',
    extensionsInsectOrAnimalBite: 'Insect or animal bite',
    extensionsMedicalExpenseTripleIndemnityForRobbery: 'Medical expense - triple indemnity for robbery',
    extensionsMedicalTreatmentDengueFever: 'Medical treatment for dengue fever',
    extensionsStikeAndRiot: 'Strike and riot',
    extensionsMotorcycling: 'Motorcycle accident injuries',
    iemaEmergencyMedicalEvacuationDueToAccident: 'Emergency medical evacuation due to accident',
    iemaEmergencyMedicalRepatriationDueToAccident: 'Emergency medical repatriation due to accident',
    iemaRepatriationOfMortalRemainsDueToAccident: 'Repatriation of mortal remains due to accident',
  },
  personalAccidentPolicyClaim: {
    termsAndConditions: 'I/We the insured do solemnly and sincerely declare that I/we have complied with the conditions of the policy and have not deliberately caused the said loss or damage to get benefits thereby by fraud or willful misrepresentation, and that the information shown on this form is true and that I/we have not hidden any information relating to this claim.',
  },
  personalAccidentClaimTypes: {
    death: 'Death',
    permanentAndTotalDisablement: 'Permanent and total disablement',
    permanentAndPartialDisablement: 'Permanent and partial disablement',
    medicalExpense: 'Medical expense',
    cashAllowance: 'Cash allowance',
    internationalEmergencyMedicalAssistance: 'International emergency medical assistance',
    others: 'Others',
  },
  cancerInsurancePage: {
    headerSection: {
      title: 'Cancer insurance',
      subTitle: 'Cancer insurance helps you and your family stay strong emotionally, physically, and most importantly financially in case of being diagnosed with cancer.',
      item1: 'Get free instant cancer insurance quotes',
      item2: 'Affordable premium from top cancer insurance companies',
      item3: 'Find out ways to save money on cancer insurance',
      item4: 'Compare cancer insurance rates for free',
    },
    definitionSection: {
      title: 'What is Cancer Insurance?',
      description: 'Cancer insurance is a specialized policy intended to minimize the financial and emotional impact on you and your family in the event of a cancer diagnosis. In the unfortunate event of being diagnosed with cancer, a cancer insurance policyholder will receive a lump-sum payment for medical treatment and use it as per needs.',
    },
    howItWorksSection: {
      title: 'How Does Cancer Insurance Work?',
      description: 'Once an individual is diagnosed with cancer, a cancer insurance policy becomes effective. Upon diagnosis, the insurance company disburses a lump sum payment equivalent to the sum insured amount, which can be utilized to offset cancer treatment expenses. The claim amount is typically disbursed at different stages of diagnosis, such as minor, major, and critical, as long as the diagnosis occurs within the policy year. Nevertheless, it\'s important to note that cancer insurance policies do not provide death, maturity, or surrender benefits.',
    },
    benefitSection: {
      title: 'Benefits of Cancer Insurance Policy',
      subTitle: 'Holding a cancer insurance policy is one of the most important decisions you can make for yourself and your loved ones. Cancer insurance can provide you with many benefits, as such:',
      item1: {
        label: 'Lump sum payments',
        desc: 'Many plans provide a lump sum payment upon diagnosis, allowing flexibility in using funds for treatment, living expenses, or other needs.',
      },
      item2: {
        label: 'Income replacement',
        desc: 'Some policies offer income benefits to help replace lost wages during treatment.',
      },
      item3: {
        label: 'Coverage for specific expenses',
        desc: 'Certain plans cover specific expenses like surgery, chemotherapy, radiation, or hospitalization.',
      },
      item4: {
        label: 'Coverage against most types of cancers',
        desc: 'Most cancer insurance companies cover against most types of cancer, which is very beneficial.',
      },
      item5: {
        label: 'Multiple cancer stages',
        desc: 'It covers all types of cancer at all stages',
      },
      item6: {
        label: 'Premium waiver',
        desc: 'In case of being diagnosed with cancer, certain insurance companies might waive the premium paid for cancer insurance policy.This is a great benefit as it lessens the financial burden of paying a premium.',
      },
      item7: {
        label: 'No-claim bonus',
        desc: 'If there are no claims within a specified policy period, most insurance companies can lower the premium paid for cancer insurance policy.',
      },
      item8: {
        label: 'Peace of mind',
        desc: 'A cancer diagnosis can be overwhelming, and the financial burden can add to the stress. Cancer insurance can help ease some of that worry by providing financial security during a difficult time so that you concentrate on your health and recovery and get to living your life back.',
      },
    },
    whyNeedSection: {
      title: 'Why Do You Need Cancer Insurance Cover?',
      subTitle: 'Cancer treatment can be incredibly expensive, even with regular health insurance. Cancer insurance provides a crucial financial safety net by:',
      item1: {
        label: 'Covering unexpected costs',
        desc: 'It helps pay for medical expenses, medications, treatments, and other related costs that your regular health insurance might not fully cover.',
      },
      item2: {
        label: 'Providing financial stability',
        desc: 'Cancer treatment can disrupt your income, so insurance can help maintain your financial health.',
      },
      item3: {
        label: 'Reducing financial stress',
        desc: 'Knowing you have financial protection can help alleviate the emotional burden of a cancer diagnosis.',
      },
      item4: {
        label: 'Offering flexibility',
        desc: 'Many cancer insurance plans provide a lump sum payment, allowing you to use the money as needed.',
      },
    },
    noCoverSection: {
      title: 'Exclusions under Cancer Insurance Policy',
      subTitle: 'While cancer insurance offers valuable financial support, it\'s essential to understand the limitations of the coverage. Here are some common exclusions found in cancer insurance policies:',
      item1: 'Certain types of skin cancer may be excluded',
      item2: 'Any type of cancer directly or indirectly influenced or aggravated by sexually transmitted diseases, HIV, or AIDS',
      item3: 'Cancer arising from any congenital or pre-existing conditions',
      item4: 'Any disease caused by consumption of drugs (except under the proper direction of a Medical Practitioner), narcotic substances, poison or alcohol is excluded',
      item5: 'Cosmetic or plastic surgery, as well as similar treatments, are not covered',
      item6: 'Cancer resulting from exposure to biological, nuclear, or chemical contamination',
      item7: 'Any cancer caused by contact with radiation or radioactivity from sources other than diagnostic or therapeutic sources',
      note: 'It\'s crucial to carefully read the policy documents to understand the specific exclusions. The terms and conditions can vary significantly between insurers.\nNote: This information is general in nature and may not apply to all cancer insurance policies. Always refer to your specific policy for detailed coverage information.',
    },
    whenBuySection: {
      title: 'When Should You Buy Cancer Insurance?',
      item1: 'If there is a history of cancer in your family, it\'s worth considering purchasing a cancer insurance plan.',
      item2: 'If you believe you have an increased risk of developing cancer due to environmental or other factors, it may be prudent to look into cancer insurance.',
      item3: 'If you lack the necessary savings to cover substantial medical expenses, a cancer insurance plan could be beneficial.',
      item4: 'If your current health insurance policy does not offer adequate coverage, it might be wise to look into purchasing a cancer insurance plan.',
      item5: 'If you are the sole breadwinner in your family, it\'s advisable to consider getting a cancer insurance plan.',
    },
    chooseRightPlanSection: {
      title: 'How to Choose the Right Cancer Insurance Policy',
      subTitle: 'Choosing the right cancer insurance policy can be overwhelming due to the variety of options available. Here are some key factors to consider:',
      understandNeeds: {
        title: 'Understand Your Needs',
        item1: {
          label: 'Assess your risk',
          desc: 'Consider your family history, lifestyle factors, and overall health to determine your risk of developing cancer.',
        },
        item2: {
          label: 'Evaluate your financial situation',
          desc: 'Determine how much coverage you can afford and how much you might need in case of a cancer diagnosis.',
        },
        item3: {
          label: 'Consider your healthcare preferences',
          desc: 'Research different treatment options and choose a policy that covers the treatments you might need.',
        },
      },
      keyFeatures: {
        title: 'Key Features to Look For',
        item1: {
          label: 'Comprehensive coverage',
          desc: 'Ensure the policy covers a wide range of cancer types, stages, and treatments.',
        },
        item2: {
          label: 'Benefit structure',
          desc: 'Understand how the policy pays out benefits (lump sum, monthly, or combination).',
        },
        item3: {
          label: 'Waiting period',
          desc: 'Be aware of the waiting period before coverage kicks in.',
        },
        item4: {
          label: 'Claim settlement ratio',
          desc: 'Check the insurer\'s reputation for timely claim settlements.',
        },
        item5: {
          label: 'Additional benefits',
          desc: 'Look for policies that offer additional benefits like coverage for early-stage detection, chemotherapy, radiation, surgery, and post-treatment care.',
        },
      },
      comparePolicies: {
        title: 'Compare Policies',
        item1: {
          label: 'Get multiple quotes',
          desc: 'Compare policies from different insurers to find the best value for your money.',
        },
        item2: {
          label: 'Read the fine print',
          desc: 'Carefully review the policy documents to understand the coverage, exclusions, and terms and conditions.',
        },
        item3: {
          label: 'Seek professional advice',
          desc: 'Consider consulting with a financial advisor or insurance agent to help you make an informed decision.',
        },
      },
    },
    frequentlyAskedQuestionSection: {
      title: 'Frequently Asked Questions About Cancer Insurance',
      q1: 'What types of cancer does the cancer insurance policy cover?',
      a1: 'Breast, stomach, ovary, prostate, lung, and hypopharynx (lower throat) cancer are covered by most cancer insurance providers. ',
      q2: 'What is the right age to buy a cancer insurance policy?',
      a2: 'The insured must be between the ages of 1 - 60 and can renew their insurance up to the age of 70 or 74 depending on certain insurers.',
      q3: 'Can I opt for a cancer insurance policy after I’m diagnosed with cancer?',
      a3: 'It is almost impossible to buy a new cancer insurance policy after you have been diagnosed with cancer.',
      q4: 'Are treatments such as radiation and chemotherapy covered under Cancer Insurance Policy?',
      a4: 'Yes, both of these treatment methods are covered under cancer insurance policy.',
      q5: 'Do I need preliminary health checkups?',
      a5: 'No. Preliminary health checkups are not required. You simply answer medical questions about your health honestly and truthfully, then you will get covered.',
    },
    disclaimer: 'Disclaimer: This landing page is for cancer insurance education only. It does not reflect on any insurance policies.',
  },
  cancerQuote: {
    geographicCoverage: 'Geographic coverage',
    annualLimit: 'Annual limit',
    cashlessHospitals: 'Cashless hospitals',
    coverAmount: 'Cover amount',
    planBenefits: 'Plan Benefits',
  },
  cancerQuoteResultPage: {
    myAge: 'Myself ({{age}} years old)',
    totalPlans: '{{count}} cancer insurance plans',
  },
  cancerQuoteDetailsPage: {
    membersCovered: {
      title: 'Members covered',
      age: '{{age}} {{pluralizedYear}} old',
      edit: 'Edit members',
    },
  },
  cancerQuoteBuyPage: {
    title: 'Buy cancer insurance policy',
    subTitle: 'Please fill in insured persons details which you wish to include in the same policy and next answer the questionnaire.',
    medicalQuestions: {
      error: {
        title: 'Unqualified members found!',
        description: 'Members listed below are not fulfilled with all the requirements demanded by the insurance company.\nTo proceed further, you have to make sure that you and any members don\'t have any health problems mentioned above or you remove unqualified members from the quote so that you will get a revised quote.',
      },
    },
  },
  cancerQuoteReviewPage: {
    memberDetailsTitle: 'Member details',
  },
  cancerPolicyClaim: {
    termsAndConditions: 'I/We the insured do solemnly and sincerely declare that I/we have complied with the conditions of the policy and have not deliberately caused the said loss or damage to get benefits thereby by fraud or willful misrepresentation, and that the information shown on this form is true and that I/we have not hidden any information relating to this claim.',
  },
  user: {
    fullName: 'Full name',
    fullNameKm: 'Full name in Khmer',
    firstName: 'First name',
    firstNameKm: 'First name in Khmer',
    lastName: 'Last name',
    lastNameKm: 'Last name in Khmer',
    occupation: 'Occupation',
    personalCode: 'National ID',
    email: 'Email',
    mobileNumber: 'Mobile number',
    dob: 'Date of Birth',
    age: 'Age',
    yearsOld: '{{age}} {{pluralizedYear}} old',
    gender: 'Gender',
    address: 'Address',
    chargeFinalAmount: 'Total Premium Amount',
    noMobileNumber: 'No mobile number',
    mobileNumberOrUsername: 'Mobile number or username',
  },
  loadingError: {
    title: 'Cannot fetch data',
    desc: 'We could not fetch the necessary data at the moment',
  },
  carPlanTypes: {
    thirdParty: 'Third-Party',
    comprehensive: 'Comprehensive/Standard',
  },
  iAgreeToTermAndPolicy: 'I agree to <term>terms and conditions</term> and <policy>privacy policy</policy>',
  carUnknown: {
    title: 'Comprehensive plans are not found',
    description: 'We are currently unable to show the comprehensive plans for your selected car. Please contact our support team.',
  },
  claimForm: {
    claimType: {
      label: 'Claim type',
    },
    completedClaimForm: {
      label: 'Completed claim form',
    },
    supportingDocuments: {
      label: 'Supporting documents',
    },
    invoice: {
      label: 'Invoice',
    },
    totalClaimedAmount: {
      label: 'Total claimed amount',
    },
  },
  claimStatuses: {
    submitted: 'Submitted',
    reviewing: 'Reviewing',
    nonPayable: 'Nonpayable',
    paid: 'Paid',
    final: 'Final',
  },
  userStepForm: {
    title: 'We would like to support you.',
    support: 'Let us know about you, so our frontlines can support you along the way.',
    authorized: 'I authorize MGA Cover to use the provided mobile number to discuss my insurance needs.',
  },
  quoteSupportCard: {
    text: 'Cannot find what you\'re looking for? Create one right away. Our frontlines will support you with customization to your needs.',
  },
  company: {
    name: 'Company name',
  },
  insurance: {
    type: 'Insurance type',
    quoteRequest: 'Thank you for your inquiry. We have received your request for a quote, and one of our consultants will be in touch shortly to provide you with tailored insurance solutions.',
  },
  corporateInsurance: {
    benefits: 'Protecting your business starts with the right coverage. Take the first step today by telling us a bit about your needs, and we’ll match you with an expert consultant who understands your industry and priorities.',
    howto: 'Request a free, no-obligation quote now, and let us provide you with customized insurance solutions designed to keep your business secure. Your peace of mind is our priority—reach out today, and we’ll handle the rest.',
  },
  alert: {
    premiumChange: {
      title: 'Premium Change Alert!',
      effectiveDate: 'Your policy effective date has passed. Your age may vary, causing the premium to change. Unfortunately, you won\'t be able to check out this purchase order immediately. Please contact our support for assistance.',
    },
  },
  form: {
    inputs: {
      date: {
        help: 'Please select or type a date',
      },
    },
  },
};
