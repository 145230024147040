import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormGroupControl } from '@components/Base';

type Props = {
  member: string;
  title: string;
  disabled?: boolean;
};

const BirthDateFormControl = ({ member, title, disabled = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Card className="mb-3" data-testid={member}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <FormGroupControl
              type="date"
              name={`${member}.dob`}
              label={t('user.dob')}
              help={t('form.inputs.date.help')}
              required
              disabled={disabled}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export { BirthDateFormControl };
