import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHref, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Stack, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { PersonalAccidentQuote } from '@models/PersonalAccidentQuote';
import { FeaturedPersonalAccidentPlanBenefits } from '@models/PersonalAccidentPlanBenefit';
import { getPersonalAccidentQuoteDetailsPageRoute } from '@components/PersonalAccidentQuoteDetailsRoute/route';
import { getPersonalAccidentPlanBenefitDescription } from '@utils/personal-accident-quote';
import { amountToUsd } from '@utils/currency';

import { compareAdd, compareRemove } from '../action';
import { selectPersonalAccidentQuoteResultPage } from '../selector';
import { usePersonalAccidentQuoteResultPageParams } from '../hook';

interface Props {
  personalAccidentQuote: PersonalAccidentQuote;
}

const PersonalAccidentQuoteItem = ({ personalAccidentQuote }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = usePersonalAccidentQuoteResultPageParams();
  const [checked, setChecked] = useState(false);
  const { comparablePersonalAccidentQuotes } = useSelector(selectPersonalAccidentQuoteResultPage);
  const {
    personalAccidentPlanId,
    personalAccidentPlanName,
    geographicCoverage,
    annualLimitAmount,
    totalPremium,
    personalAccidentPlanBenefits,
  } = personalAccidentQuote;
  const personalAccidentQuoteDetailsPageHref = useHref(
    getPersonalAccidentQuoteDetailsPageRoute({ ...params, personalAccidentPlanId }),
  );
  const permanentAndPartialDisablementDescription = getPersonalAccidentPlanBenefitDescription(
    personalAccidentPlanBenefits,
    FeaturedPersonalAccidentPlanBenefits.PermanentAndPartialDisablementDueToAccident,
  );

  const handleBuyNowClick = () => {
    navigate(getPersonalAccidentQuoteDetailsPageRoute({ ...params, personalAccidentPlanId }));
  };

  const handleCompare = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    if (currentTarget.checked) {
      dispatch(compareAdd({ personalAccidentQuote: personalAccidentQuote }));
    } else {
      dispatch(compareRemove({ personalAccidentQuote: personalAccidentQuote }));
    }
  };

  useEffect(() => {
    setChecked(some(comparablePersonalAccidentQuotes, { personalAccidentPlanId }));
  }, [comparablePersonalAccidentQuotes]);

  return (
    <Row className="g-3">
      <Col lg={3}>
        <Card.Title>{personalAccidentPlanName}</Card.Title>
        <Card.Text>
          <strong>{t('personalAccidentQuote.geographicCoverage')} - </strong>
          {geographicCoverage}
        </Card.Text>
        <Card.Text>
          <strong>{t('personalAccidentQuote.annualLimit')} - </strong>
          {amountToUsd(annualLimitAmount)}
        </Card.Text>
        <Card.Text>
          <ToggleButton
            id={`personal-accident-${personalAccidentPlanId}`}
            type="checkbox"
            variant="outline-secondary"
            value={personalAccidentPlanId}
            checked={checked}
            onChange={handleCompare}
            className="w-100"
          >
            {t('common:compare')}
          </ToggleButton>
        </Card.Text>
      </Col>

      <Col lg={6}>
        <Card.Text>
          <strong>{t('personalAccidentPlanBenefit.basicBenefitsDeathDueToAccident')} - </strong>
          {getPersonalAccidentPlanBenefitDescription(
            personalAccidentPlanBenefits,
            FeaturedPersonalAccidentPlanBenefits.DeathDueToAccident,
          )}
        </Card.Text>
        <Card.Text>
          <strong>{t('personalAccidentPlanBenefit.basicBenefitsPermanentAndTotalDisablementDueToAccident')} - </strong>
          {getPersonalAccidentPlanBenefitDescription(
            personalAccidentPlanBenefits,
            FeaturedPersonalAccidentPlanBenefits.PermanentAndTotalDisablementDueToAccident,
          )}
        </Card.Text>
        {permanentAndPartialDisablementDescription && (
          <Card.Text>
            <strong>
              {t('personalAccidentPlanBenefit.basicBenefitsPermanentAndPartialDisablementDueToAccident')} -{' '}
            </strong>
            {permanentAndPartialDisablementDescription}
          </Card.Text>
        )}
        <Card.Text>
          <strong>{t('personalAccidentPlanBenefit.basicBenefitsMedicalExpenseDueToAccident')} - </strong>
          {getPersonalAccidentPlanBenefitDescription(
            personalAccidentPlanBenefits,
            FeaturedPersonalAccidentPlanBenefits.MedicalExpenseDueToAccident,
          )}
        </Card.Text>
      </Col>

      <Col lg={3}>
        <Stack gap={3} className="text-center">
          <Card.Text className="mb-0">
            <strong>
              {t('common:premium')}: <span className="fs-5">{amountToUsd(totalPremium)}</span>
            </strong>
          </Card.Text>
          <Button onClick={handleBuyNowClick} variant="primary" size="lg" className="w-100">
            {t('common:buyNow')}
          </Button>
          <Card.Link href={personalAccidentQuoteDetailsPageHref} className="text-decoration-none">
            {t('common:planDetails')}
            <FontAwesomeIcon icon={faArrowRight} size="xs" className="ms-2" />
          </Card.Link>
        </Stack>
      </Col>
    </Row>
  );
};

export { PersonalAccidentQuoteItem };
