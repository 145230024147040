import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { stringify } from 'qs';
import pluralize from 'pluralize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { ConfirmModal } from '@components/Base';
import { useHealthQuoteResultPageParams, useInsurancePage } from '../hook';

const SubHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const [showDialog, setShowDialog] = useState(false);
  const { current, others } = useInsurancePage();

  return (
    <Container className="my-3">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
        <div className="d-flex flex-column flex-md-row">
          <div className="d-flex flex-row align-items-center me-3">
            <span className="d-block fw-bold">{pluralize(t('common:member'), 2)}: </span>
          </div>

          <div className="d-flex flex-row align-items-center me-3">
            <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3 text-primary" />
            <span className="d-block">{t('common:myself')}</span>
          </div>

          {params.spouse && (
            <div className="d-flex flex-row align-items-center me-3">
              <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3 text-primary" />
              <span className="d-block">{t('common:spouse')}</span>
            </div>
          )}

          {params.sons && (
            <div className="d-flex flex-row align-items-center me-3">
              <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3 text-primary" />
              <span className="d-block">
                {`${pluralize(t('common:son'), params.sons.length)}: ${params.sons.length}`}
              </span>
            </div>
          )}

          {params.daughters && (
            <div className="d-flex flex-row align-items-center me-3">
              <FontAwesomeIcon icon={faCircleCheck} size="sm" className="me-3 text-primary" />
              <span className="d-block">
                {`${pluralize(t('common:daughter'), params.daughters.length)}: ${params.daughters.length}`}
              </span>
            </div>
          )}

          <Button variant="outline-primary" onClick={() => setShowDialog(true)} className="my-2">
            {t('common:change')}
          </Button>
        </div>

        <Dropdown>
          <Dropdown.Toggle className="w-100">{current.name}</Dropdown.Toggle>
          <Dropdown.Menu>
            {map(others, ({ name, href }) => (
              <Dropdown.Item key={name} href={href} target="_blank">
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <ConfirmModal
          show={showDialog}
          onHide={() => setShowDialog(false)}
          onConfirm={() =>
            navigate(
              {
                pathname: current.homePath,
                search: stringify(params),
              },
              { replace: true },
            )
          }
        />
      </div>
    </Container>
  );
};

export { SubHeader };
