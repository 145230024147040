import { array, boolean, object, string } from 'yup';

import { Genders } from '@models/User';
import { stringify } from 'qs';

export type PeopleParams = {
  me: {
    gender: Genders;
    dob: string;
  };
  spouse?: {
    dob: string;
  };
  sons?: {
    dob: string;
  }[];
  daughters?: {
    dob: string;
  }[];
};

export type HealthQuotesFilterParams = {
  inpatient: boolean;
  outpatient: boolean;
  maternity: boolean;
  healthCheckup: boolean;
  vision: boolean;
  dental: boolean;
};

export type HealthQuoteResultPageParams = PeopleParams & {
  filters?: HealthQuotesFilterParams;
  activationDate: string;
};

export const peopleParamsSchema = object({
  me: object({
    gender: string(),
    dob: string(),
  }),
  spouse: object({
    dob: string(),
  }).default(undefined),
  sons: array().of(
    object({
      dob: string(),
    }),
  ),
  daughters: array().of(
    object({
      dob: string(),
    }),
  ),
});

export const healthQuotesFilterParamsSchema = object({
  ipd: boolean().default(false),
  opd: boolean().default(false),
  healthCheckup: boolean().default(false),
  dental: boolean().default(false),
  maternity: boolean().default(false),
  vision: boolean().default(false),
});

export const healthQuoteResultPageParamsSchema = peopleParamsSchema.concat(healthQuotesFilterParamsSchema).concat(
  object({
    activationDate: string()
  })
);

export const HEALTH_QUOTE_RESULT_PAGE_URL = '/health-insurance-quote-results';

export const getHealthQuoteResultPageRoute = (params: HealthQuoteResultPageParams) => {
  return {
    pathname: HEALTH_QUOTE_RESULT_PAGE_URL,
    search: stringify(params),
  };
};
