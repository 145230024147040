import { mergeInitialValues } from '@utils/formik';
import { useParseParams } from '@hooks/search-params';

import { formValuesParamsSchema, FormValues } from '../HealthQuoteBuyForm';
import { useHealthQuoteEdit } from '../hook';

export const useHealthQuoteReview = () => {
  const healthQuote = useHealthQuoteEdit();
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  const values = mergeInitialValues(
    initialValues,
    {
      ...healthQuote?.people,
      activationDate: healthQuote?.activationDate,
      healthPlanId: healthQuote?.healthPlanId,
    }
  );

  return { healthQuote, values };
};
