import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '@components/Base';
import CancerQuotePremiumCardBody from '@components/Feature/CancerQuote/CancerQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { OrderStatuses } from '@models/Order';

import { cancerOrderCreate } from '../action';
import { CancerOrderCreatePayload } from '../cancerOrderCreate';
import { getCancerQuoteBuyCustomPageRoute, getCancerQuoteBuyEditPageRoute } from '../route';
import { PolicySummaryCardBody } from './policy-summary-card-body';
import { FeatureCardBody } from './feature-card-body';
import { BenefitCardBody } from './benefit-card-body';
import { MemberCardBody } from './member-card-body';
import { selectIsCustomer, selectIsFrontline } from '@components/Auth';

import { useCancerQuoteReview } from './hook';

const CancerQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cancerQuote, values } = useCancerQuoteReview();
  const isFrontline = useSelector(selectIsFrontline);
  const isCustomer = useSelector(selectIsCustomer);

  const { status, activationDate } = cancerQuote || {};
  const isPending = status === OrderStatuses.Pending;
  const isLocked = status === OrderStatuses.Locked;
  const isValidActivationDate = moment(activationDate).isAfter(moment(), 'day');
  const shouldEdit = isPending && isValidActivationDate;
  const shouldPay = isCustomer && (shouldEdit || isLocked);
  const shouldCustomPremium = isFrontline && isPending;

  if (!cancerQuote) return null;
  const { cancerOrderId } = cancerQuote;

  const handlePay = () => {
    if (!cancerQuote) return null;

    const payload: CancerOrderCreatePayload = { cancerQuoteId: cancerQuote.cancerOrderId };
    dispatch(cancerOrderCreate(payload));
  };

  const handleCustom = () => {
    navigate(getCancerQuoteBuyCustomPageRoute({ cancerOrderId }));
  };

  const handleEdit = () => {
    if (!cancerQuote) return null;

    navigate({
      ...getCancerQuoteBuyEditPageRoute({ cancerQuoteId: cancerQuote.cancerOrderId }),
      search: stringify(values),
    });
  };

  return (
    <>
      {isUndefined(cancerQuote) && <Loading />}

      {cancerQuote && (
        <Container className="my-5">
          <Row>
            <Col lg={7}>
              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>

                <PolicySummaryCardBody {...cancerQuote} />
                <FeatureCardBody {...cancerQuote} />
                <BenefitCardBody {...cancerQuote} />
                <MemberCardBody {...cancerQuote.people} onEdit={shouldEdit ? handleEdit : undefined} />
              </Card>
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <CancerQuotePremiumCardBody {...cancerQuote} />

                <Card.Body>
                  {!cancerQuote.isValidMedicalQuestionnaire && (
                    <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
                  )}

                  {isFrontline && (
                    <div className="text-end">
                      <Link
                        to={`/invoices/${cancerQuote.orderEncryptedId}.pdf`}
                        target="_blank"
                        className="d-inline-block my-3"
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {t('common:downloadInvoice')}
                      </Link>
                    </div>
                  )}

                  {shouldCustomPremium && (
                    <Button onClick={handleCustom} className="mb-3 w-100" variant="secondary">
                      Custom
                    </Button>
                  )}

                  {shouldPay && (
                    <Button
                      disabled={!cancerQuote.isValidMedicalQuestionnaire}
                      onClick={handlePay}
                      className="btn-primary btn-lg w-100"
                      size="lg"
                    >
                      <span className="me-2">{t('common:payNow')}</span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}
                </Card.Body>
              </Card>

              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { CancerQuoteReviewPage };
