import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { useFormikContext } from 'formik';

import { tOrdinalItem } from '@components/i18n';
import { getAge } from '@utils/datetime';
import { MemberAnswerItem } from './member-answer-item';
import { FormValues } from '../schema';

type Props = {
  questionIdx: number;
};

const MemberAnswerList = ({ questionIdx }: Props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();

  return (
    <>
      {!!values.me?.medicalQuestionnaire && (
        <MemberAnswerItem
          age={getAge(values.me.dob)}
          label={`${t('common:myself')}`}
          name={`me.medicalQuestionnaire.${questionIdx}.remark`}
          isVisible={values.me.medicalQuestionnaire[questionIdx]?.answer == 'yes'}
        />
      )}

      {!!values.spouse?.medicalQuestionnaire && (
        <MemberAnswerItem
          age={getAge(values.spouse.dob)}
          label={`${t('common:spouse')}`}
          name={`spouse.medicalQuestionnaire.${questionIdx}.remark`}
          isVisible={values.spouse.medicalQuestionnaire[questionIdx]?.answer == 'yes'}
        />
      )}

      {!!values.sons &&
        map(
          values.sons,
          (son, idx) =>
            !!son.medicalQuestionnaire && (
              <MemberAnswerItem
                key={idx}
                age={getAge(son.dob)}
                label={tOrdinalItem(idx + 1, 'common:son')}
                name={`sons.${idx}.medicalQuestionnaire.${questionIdx}.remark`}
                isVisible={son.medicalQuestionnaire[questionIdx]?.answer == 'yes'}
              />
            ),
        )}

      {!!values.daughters &&
        map(
          values.daughters,
          (daughter, idx) =>
            !!daughter.medicalQuestionnaire && (
              <MemberAnswerItem
                key={idx}
                age={getAge(daughter.dob)}
                label={tOrdinalItem(idx + 1, 'common:daughter')}
                name={`daughters.${idx}.medicalQuestionnaire.${questionIdx}.remark`}
                isVisible={daughter.medicalQuestionnaire[questionIdx]?.answer == 'yes'}
              />
            ),
        )}
    </>
  );
};

export { MemberAnswerList };
