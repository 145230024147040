import React, { useCallback } from 'react';
import { Container, Row, Col, Card, Button, Stack, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import { useNavigate, useHref } from 'react-router-dom';

import { HealthQuote } from '@models/HealthQuote';
import { amountToUsd } from '@utils/currency';
import { useHealthQuoteResultPageParams } from '@components/HealthQuoteResultPage';

import { getHealthQuoteSendEmailPageRoute } from '../route';
import { getHealthQuoteBuyNewPageRoute } from '@components/HealthQuoteBuyRoute/route';

type Props = Pick<HealthQuote, 'healthPlanId' | 'insurer' | 'healthPlanName' | 'annualLimitAmount' | 'totalPremium'>;

const HeaderSection = ({ healthPlanId, insurer, healthPlanName, annualLimitAmount, totalPremium }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useHealthQuoteResultPageParams();
  const healthQuoteSendEmailHref = useHref(getHealthQuoteSendEmailPageRoute({ ...params, healthPlanId }));

  const handleBuy = useCallback(() => {
    navigate(getHealthQuoteBuyNewPageRoute({ ...params, healthPlanId }));
  }, []);

  return (
    <section>
      <Container>
        <Card className="shadow">
          <Card.Body>
            <Row className="g-3">
              <Col lg={2} className="text-center">
                <Image src={insurer.logo} className="rounded img-fluid" />
              </Col>
              <Col lg={10}>
                <Row className="g-3">
                  <Col lg={4}>
                    <Stack gap={3} className="">
                      <Card.Title className="mb-0">{healthPlanName}</Card.Title>
                      <Card.Text>{insurer.name}</Card.Text>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Stack gap={3}>
                      <Card.Text className="mb-0">{t('healthQuoteDetailsPage.coverAmount')}</Card.Text>
                      <Card.Text className="fs-5 fw-bold">{amountToUsd(annualLimitAmount)}</Card.Text>
                    </Stack>
                  </Col>
                  <Col lg={4}>
                    <Button variant="primary" size="lg" className="w-100" onClick={handleBuy}>
                      <span className="d-block">{t('common:buyNow')}</span>
                      <strong className="fs-5 ms-3">
                        {amountToUsd(totalPremium)}/{lowerCase(t('common:year'))}
                      </strong>
                    </Button>
                    <Button variant="link" href={healthQuoteSendEmailHref} target="_blank" rel="noreferrer noopener">
                      {t('common:sendQuoteToMyEmail')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export { HeaderSection };
